import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';
// import promotion_routes from './promotion_routes';
import user_routes from './user_routes';

// const all_routes = routes;
const all_routes = routes.concat(user_routes);

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // return savedPosition

      // ref: not working https://forum.vuejs.org/t/cant-get-scrollbehavior-working/29077/13
      /* app.$root.$once('scrollAfterEnter', () => {
        console.log('scrollsavedPosition', savedPosition)
        resolve(position);
      }); */

      //dirty way to restore scroll position
      return new Promise((resolve, reject) => {
        console.log('scrollsavedPosition', savedPosition);
        setTimeout(() => {
          resolve(savedPosition);
        }, 100);
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: all_routes,
});
