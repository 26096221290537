import Vue from 'vue';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    introspect: {},
    relationship_options: {},
    selected_option: {},
    pagination: {}
  },
  getters: {
    introspect: state => {
      return state.introspect;
    },
    getRelationships: state => params => {
      var primary_class_name = params.primary;
      var relationship_direction = params.direction;
      var secondary_class_name = params.secondary;

      var relationship_direction_inverse = null;
      if (relationship_direction == 'from') {
        relationship_direction_inverse = 'to';
      } else if (relationship_direction == 'to') {
        relationship_direction_inverse = 'from';
      } else {
        console.error('relationship_direction must be either "from" or "to", ' + relationship_direction + 'given');
        return;
      }
      var relavantRelationships = [];
      state.introspect.relationships.forEach(relationship => {
        var match = false;
        if (relationship[relationship_direction_inverse]['class_name'] == primary_class_name) {
          if (secondary_class_name) {
            if (relationship[relationship_direction]['class_name'] == primary_class_name) {
              match = true;
            }
          } else {
            match = true;
          }
        }
        if (match) {
          relavantRelationships.push(_.merge(relationship));
        }
      });
      return relavantRelationships;
    },
    getPaginationState: state => params => {
      let pagination = null;
      let stateName = params.stateName;
      if (stateName) {
        pagination = state.pagination[stateName];
      } else {
        console.error('invalid getPaginationState params');
      }

      return pagination;
    }
  },
  mutations: {
    setIntrospect: (state, data) => {
      // state.introspect = _.assign(state.introspect, data);
      //   console.log(data)
      Vue.set(state.introspect, _.assign(state.introspect, data));
    },
    getIntrospect(state) {
      Vue.prototype.$api
        .get('/descriptor/introspect')
        .then(response => {
          var data = Vue.prototype.$api.getData(response);
          if (data) {
            Vue.set(state.introspect, _.assign(state.introspect, data));
          }
        })
        .catch(axios_error => {
          // error callback
          console.error(axios_error);
        });
    },
    setPaginationState(state, params) {
      let stateName = params.stateName;
      let pagination = params.pagination;
      if (stateName && pagination) {
        state.pagination[stateName] = pagination;
      } else {
        console.error('invalid setPaginationState params');
      }
    }
  },
  actions: {}
};
