export default function(Vue, options) {
  let router = options.router;
  // let store = options.store
  let login_path = options.login_path;

  if (!router) {
    console.error('router instance required, to redirect to login page');
  }

  if (!login_path) {
    console.error('login_path required, to redirect to login page');
  }

  //Navigation guard
  router.beforeEach(async (to, from, next) => {
    let go_to_login = false;

    if (to.meta) {
      if (!to.meta.is_public) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        if (Vue.prototype.$auth.isAuth == null) {
          //authentication status unknown, try to get it:
          let isAuth = await Vue.prototype.$auth.checkAuth();

          if (isAuth == false) {
            go_to_login = true;
          }
        } else if (Vue.prototype.$auth.isAuth == false) {
          go_to_login = true;
        }
      }

      var permissions = to.meta.permissions;
      // console.log(permissions)
      if (permissions) {
        // console.log(permissions, Vue.prototype.$auth.havePermissions(permissions))
        if (!Vue.prototype.$auth.havePermissions(permissions)) {
          go_to_login = true;
        }
      }
    }

    if (go_to_login) {
      console.warn('Route Guard check failed, jump to login');
      if (to.path != login_path) {
        //avoid infinite loop
        next({
          path: login_path,
        });
        return;
      }
    }

    next(); // make sure to always call next()!
  });
}
