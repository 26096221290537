// import something here
// import Vue from 'vue'
import localForage from 'localforage';
import _ from 'lodash';

var authForage = localForage.createInstance({
  name: 'authForage',
});

//TODO: should be renamed to Session plugin instead of Auth plugin

const servers = [
  {
    name: 'Production',
    url: 'https://api.crm.jantzen.my',
  },
  {
    name: 'Staging',
    url: 'https://api.testcrm.jantzen.com.my',
  },
];

// leave the export, even if you don't use it
export default function (Vue, options) {
  // something to do

  const authVue = new Vue({
    data() {
      return {
        is_auth_checked: false,
        user: null,
        access_token: null,
        expires_at: null,
        server: null,
      };
    },
    computed: {
      servers() {
        return servers;
      },
      is_auth() { //alias of isAuth()
        return this.isAuth();
      },
      isAuth() {
        if (this.is_auth_checked !== true) {
          return null;
        }
        return this.user && this.access_token && this.expires_at ? true : false;
      },
    },
    created() {
      this.init();
    },
    methods: {
      set(user, access_token, expires_at) {
        let promises = [];
        promises.push(authForage.setItem('user', user));
        promises.push(authForage.setItem('access_token', access_token));
        promises.push(authForage.setItem('expires_at', expires_at));

        this.user = user;
        this.access_token = access_token;
        this.expires_at = expires_at;

        return Promise.all(promises);
      },

      setToken(access_token, expires_at) {
        let promises = [];
        promises.push(authForage.setItem('access_token', access_token));
        promises.push(authForage.setItem('expires_at', expires_at));

        this.access_token = access_token;
        this.expires_at = expires_at;

        return Promise.all(promises);
      },

      setServer(server) {

        let promises = [];
        promises.push(authForage.setItem('server', server));

        this.server = server;

        //TODO: might want to store tokens by server url, hense this should be renamed to Session plugin
        //Might even be able to make this work for different Companies

        if (this.$d) { //TODO: not really a good practice to reference other plugins...
          this.$d.init();
        }

        return Promise.all(promises);
      },

      setUser(user) {
        let promises = [];
        promises.push(authForage.setItem('user', user));

        this.user = user;

        return Promise.all(promises);
      },

      havePermissions(permissions) {
        //returns false if not all permissions match
        var user = this.user;
        if (user) {
          // console.log(user.permission_names)
          var havePermissions = true;
          if (user.permission_names) {
            permissions.forEach(permission => {
              havePermissions &= user.permission_names.includes(permission) ? true : false;
              // console.log(havePermissions)
            });
          } else {
            havePermissions = false;
          }
          // console.log('return', havePermissions)
          return havePermissions; //if all matches
        }
        // console.log('return false')
        return false;
      },

      removeAllData() { //NOTE: logs out and also resets server
        this.server = null;
        authForage.removeItem('server')

        this.destroy();
      },

      destroy() { //NOTE: for logging out only
        authForage.removeItem('user')
        authForage.removeItem('access_token')
        authForage.removeItem('expires_at')
        // authForage.clear();
        this.user = null;
        this.access_token = null;
        this.expires_at = null;
        this.is_auth_checked = false;
      },

      getToken: async function () {

        console.log('getToken')
        var access_token = await authForage.getItem('access_token');
        var expires_at = await authForage.getItem('expires_at');

        if (!access_token || !expires_at) {
          return null;
        }
        let timestamp_now;

        if (Vue.prototype.$d) {
          timestamp_now = Vue.prototype.$d.momentSynced().unix()
        } else {
          timestamp_now = Math.floor(Date.now() / 1000);
        }
        console.log('timestamp_now', timestamp_now)
        console.log('expires_at', expires_at)
        let seconds_remaining = expires_at - timestamp_now
        console.log('hours remaining' , seconds_remaining / 60 / 60)
        console.log('minutes remaining' , seconds_remaining / 60)

        let clear_token_on_expiry = true
        // let clear_token_on_expiry = false; //test purposes, don't clear ourselves, let server invalidate
        if (clear_token_on_expiry && timestamp_now > parseInt(expires_at)) { 
          console.log('token expired, logout');
          this.destroy();
          return null;
        } else {
          this.access_token = access_token;
          this.expires_at = expires_at;
          return access_token;
        }
      },

      getServer: async function () {
        var server = await authForage.getItem('server');
        if (!server) {
          console.log('no server');
        }
        this.server = server;
        return server;
      },

      getUser: async function () {
        var user = await authForage.getItem('user');
        if (!user) {
          console.log('no cached user, logout');
        }
        if (typeof user != 'object') {
          console.log('cached user invalid, logout');
        }

        if (!user || typeof user != 'object') {
          this.destroy();
          return null;
        }
        this.user = user;
        return user;
      },

      checkAuth: async function () {
        //TODO: should probably submit to server to check if token is valid, if offline, then ignore
        await this.getToken();
        await this.getServer();
        await this.getUser();

        this.is_auth_checked = true;
        return this.isAuth;
      },

      init() {
        this.checkAuth();
      },
    },
  });

  if (!Vue.prototype.$auth) {
    Object.defineProperties(Vue.prototype, {
      $auth: {
        get() {
          return authVue;
        },
      },
    });
  } else {
    console.error('Another $auth has been defined, will skip this one');
  }
}
