import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import descriptor_store_module from './descriptor_store_module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    descriptor: descriptor_store_module
  },
  state: {
    tabs: [],
    tabIndex: 0,
    accounts: [],
    pagination: {},
    promotion_season: {},
    package: {
      product_groups: {}
    },
    options: {
      //TODO: need to predefine options to ensure reactivity
      company_sectors: null,
      company_types: null,
      users_enabled: null,
      event_types: null,
      'product-type': null,
      'product-category': null,
      'product-brand': null,
      'product-group': null,
      'product-unit': null,
      tangibles: null,
      general_ledger_code: null,
      'package-type': null
    },
    loaded_options: []
  },
  getters: {
    selections: state => params => {
      var value_name = params.value_name || 'id';
      var text_name = params.text_name || 'name';

      if (_.isEmpty(state.options[params.option_name])) {
        //attempt to ensure the option is defined, for reactivity
        state.options[params.option_name] = [];
      }

      var selections = _.map(state.options[params.name], option => {
        return {
          value: option[value_name],
          text: option[text_name]
        };
      });
      if (params.nullable) {
        selections.unshift({
          value: null,
          text: '- none -'
        });
      }
      return selections;
    },
    account: state => params => {
      var existing_account = params.account;

      let existing_account_index = state.accounts.findIndex(account => {
        return account.id == existing_account.id;
      });

      return state.accounts[existing_account_index];
    }
  },
  mutations: {
    openAccountTab: (state, new_account) => {
      let existing_account_index = state.accounts.findIndex(account => {
        return account.id == new_account.id;
      });

      const numOfFixedTabs = 1; //including Main Tab

      if (existing_account_index < 0) {
        state.accounts.push(new_account);
        setTimeout(() => {
          //async work around, because tab was just newly added
          state.tabIndex = numOfFixedTabs + state.tabs.length + (state.accounts.length - 1);
          console.log('new account tab', state.tabIndex);
        }, 100);
      } else {
        state.tabIndex = numOfFixedTabs + state.tabs.length + existing_account_index;
        console.log('existing account tab', state.tabIndex);
      }
    },
    addTab: (state, new_tab) => {
      const numOfFixedTabs = 1; //including Main Tab

      state.tabs.push(new_tab);
      setTimeout(() => {
        //async work around, because tab was just newly added
        state.tabIndex = numOfFixedTabs + (state.tabs.length - 1);
        console.log('new tab', state.tabIndex);
      }, 100);
    },
    closeTab: (state, close_index) => {
      state.tabs = state.tabs.filter((tab, tab_index) => {
        return tab_index != close_index;
      });
      // state.tabIndex = 0
    },
    closeAccountTab: (state, existing_account) => {
      state.accounts = state.accounts.filter(account => {
        return account.id != existing_account.id;
      });
      // state.tabIndex = 0
    },
    setAccount: (state, account_data) => {
      let index = state.accounts.findIndex(account => {
        return account.id == account_data.id;
      });
      // state.accounts[index] = account_data
      // state.accounts[index] = _.assign(state.accounts[index], account_data)
      Vue.set(state.accounts, index, _.assign(state.accounts[index], account_data));
    },
    setAccountData: (state, params) => {
      var account_data = params.account;
      var name = params.name;
      var data = params.data;

      let index = state.accounts.findIndex(account => {
        return account.id == account_data.id;
      });
      // state.accounts[index][name] = data;
      state.accounts[index][name] = _.assign(state.accounts[index][name], data);
    },
    getOption(state, params) {
      var api_endpoint = params.endpoint;
      var api_params = params.params;
      var option_name = params.option_name;
      var data_name = params.data_name;

      var api_method = 'get';
      if (params.method) {
        api_method = params.method;
      }

      /*  if (option_name) {
         if (state.options[option_name]) {
           return
         }
       } else {
         if (state.loaded_options.includes(api_endpoint)) {
           return
         }
       } */

      if (api_method == 'get') {
        var config = {
          method: 'get',
          url: api_endpoint,
          params: api_params
        };
      } else if (api_method == 'post') {
        var config = {
          method: 'post',
          url: api_endpoint,
          data: api_params
        };
      }

      Vue.prototype
        .$api(config)
        .then(response => {
          // success callback
          var data = Vue.prototype.$api.getData(response);
          if (data) {
            var options = {};
            if (option_name && data_name) {
              options[option_name] = data[data_name];
            } else if (data_name) {
              options[data_name] = data[data_name];
            } else {
              delete data.type;
              options = data;
              state.loaded_options.push(api_endpoint);
            }
            // commit("setOption", options);
            state.options = _.assign(state.options, options);
            // console.log(params.option_name, options)
          }
        })
        .catch(axios_error => {
          // error callback
          console.error(axios_error);
        });
    }
  },
  actions: {
    openTab: ({ commit, state }, new_tab) => {
      let existing_tab_index = state.tabs.findIndex(tab => {
        if (!_.isNil(tab.id) && !_.isNil(new_tab.id)) {
          return tab.id == new_tab.id;
        }
        return false;
      });

      const numOfFixedTabs = 1; //including Main Tab

      if (existing_tab_index < 0) {
        //tab not found
        commit('addTab', new_tab);
      } else {
        state.tabIndex = numOfFixedTabs + existing_tab_index;
        console.log('existing tab', state.tabIndex);
      }
    }
  }
});
