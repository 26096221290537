import Auth from './auth';
import Api from './api';
import RouteGuard from './route_guard';
import Descriptor from './descriptor';
// import * as components from './components.js'

export default {
  install(Vue, options) {
    let router = options.router;
    let store = options.store;
    let login_path = options.login_path;
    let api_root = options.api_root;
    let api_timeout = options.api_timeout;

    let disable_auth = options.disable_auth || false;
    let disable_api = options.disable_api || false;
    let disable_route_guard = options.disable_route_guard || false;
    let disable_descriptor = options.disable_descriptor || false;

    if (!disable_auth) {
      Vue.use(Auth);
    } else {
      console.warn('auth plugin disabled');
    }

    if (!disable_api) {
      Vue.use(Api, { router, login_path, api_root, api_timeout });
    } else {
      console.warn('api plugin disabled');
    }

    if (!disable_route_guard) {
      Vue.use(RouteGuard, { router, store, login_path });
    } else {
      console.warn('route_guard plugin disabled');
    }

    if (!disable_descriptor) {
      Vue.use(Descriptor, { router });
    } else {
      console.warn('descriptor plugin disabled');
    }
  },

  // ...components,
};
