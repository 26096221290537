/* eslint-disable no-unused-vars */
// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');

const Colors = () => import('@/views/theme/Colors');
const Typography = () => import('@/views/theme/Typography');

const Charts = () => import('@/views/Charts');
const Widgets = () => import('@/views/Widgets');

// Views - Components
const Cards = () => import('@/views/base/Cards');
const Forms = () => import('@/views/base/Forms');
const Switches = () => import('@/views/base/Switches');
const Tables = () => import('@/views/base/Tables');
const Tabs = () => import('@/views/base/Tabs');
const Breadcrumbs = () => import('@/views/base/Breadcrumbs');
const Carousels = () => import('@/views/base/Carousels');
const Collapses = () => import('@/views/base/Collapses');
const Jumbotrons = () => import('@/views/base/Jumbotrons');
const ListGroups = () => import('@/views/base/ListGroups');
const Navs = () => import('@/views/base/Navs');
const Navbars = () => import('@/views/base/Navbars');
const Paginations = () => import('@/views/base/Paginations');
const Popovers = () => import('@/views/base/Popovers');
const ProgressBars = () => import('@/views/base/ProgressBars');
const Tooltips = () => import('@/views/base/Tooltips');

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons');
const ButtonGroups = () => import('@/views/buttons/ButtonGroups');
const Dropdowns = () => import('@/views/buttons/Dropdowns');
const BrandButtons = () => import('@/views/buttons/BrandButtons');

// Views - Icons
const Flags = () => import('@/views/icons/Flags');
const FontAwesome = () => import('@/views/icons/FontAwesome');
const SimpleLineIcons = () => import('@/views/icons/SimpleLineIcons');
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons');

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts');
const Badges = () => import('@/views/notifications/Badges');
const Modals = () => import('@/views/notifications/Modals');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/pages/Login');
const Register = () => import('@/views/pages/Register');

const DescriptorPage = () => import('@/descriptor/coreui/DescriptorPage');
const TableExportPage = () => import('@/views/descriptor/TableExport/TableExportPage.vue');

const Visualization = () => import('@/descriptor/coreui/Visualization');

const Visualization3D = () => import('@/descriptor/coreui/Visualization3D');

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: DefaultContainer,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'theme',
        redirect: '/theme/colors',
        name: 'Theme',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: 'colors',
            name: 'Colors',
            component: Colors,
          },
          {
            path: 'typography',
            name: 'Typography',
            component: Typography,
          },
        ],
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts,
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets,
      },
      {
        path: 'orders',
        component: () => import('@/views/descriptor/classes/Order/OrderDescriptorCard'),
        props: route => {
          return {
            enable_add: false,
          };
        },
      },
      {
        path: 'dashboard2',
        component: () => import('@/views/pages/Dashboard2'),
      },
      {
        path: 'invoices',
        component: () => import('@/views/descriptor/classes/Invoice/InvoiceDescriptorCard'),
      },
      {
        path: 'print_invoices',
        component: () => import('@/views/descriptor/classes/PrintInvoice/PrintInvoiceDescriptorCard'),
      },
      {
        path: 'daily_route_grouping',
        component: () => import('@/views/descriptor/classes/DailyRouteGrouping/DailyRouteGroupingDescriptorCard'),
      },
      {
        path: 'daily_route_grouping_mat',
        component: () => import('@/views/descriptor/classes/DailyRouteGrouping/DailyRouteGroupingDescriptorCard'),
        props: route => {
          return {
            drg_class_name: 'DailyRouteGroupingMat',
          };
        },
      },
      {
        path: 'daily_route_confirmed',
        component: () => import('@/views/descriptor/classes/DailyRouteConfirmed/DailyRouteConfirmedDescriptorCard'),
      },
      {
        path: 'daily_route_summary',
        component: () => import('@/views/descriptor/classes/DailyRouteSummary/DailyRouteSummaryDescriptorCard'),
      },
      {
        path: 'replenishment_order_dates',
        component: () => import('@/views/descriptor/classes/ReplenishmentOrderDates/ReplenishmentOrderDatesDescriptorCard'),
      },
      {
        path: 'accounts',
        component: () => import('@/views/descriptor/classes/Account/AccountDescriptorCard'),
      },
      {
        path: 'quotations',
        component: () => import('@/views/descriptor/classes/Quotation/QuotationDescriptorCard'),
      },
      {
        path: 'descriptor',
        name: 'Descriptor',
        component: DescriptorPage,
      },
      {
        path: 'descriptor/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'billing_account/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'account/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'call/CallScheduled',
        component: () => import('@/views/descriptor/classes/CallScheduled/CallScheduledDescriptorCard'),
      },
      {
        path: 'call/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'sellable/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'commission_scheme/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'commission/:class_name',
        component: DescriptorPage,
        props: route => {
          return {
            with_childs_mode: true,
            manual_get: true,
          };
        },
      },
      {
        path: 'commission/*/:class_name(.*Pending+)', //only for pending commissions
        component: DescriptorPage,
        props: route => {
          return {
            with_childs_mode: true,
            //manual_get: true,
          };
        },
      },
      {
        path: 'table_export/*/:class_name(.*Pending+)', //only for pending commissions
        component: TableExportPage,
        props: route => {
          return {
            with_childs_mode: true,
            //manual_get: true,
          };
        },
      },
      {
        path: 'commission/*/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'table_export/*/:class_name',
        component: TableExportPage,
      },
      {
        path: 'tags/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'templates/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'payment/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'constants/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'orderable/:class_name',
        component: DescriptorPage,
      },
      {
        path: 'visualization/2d',
        name: 'Visualization',
        component: Visualization,
      },
      {
        path: 'visualization/3d',
        name: 'Visualization3D',
        component: Visualization3D,
      },
      {
        path: 'quota/overview',
        name: 'Quota Overview',
        component: () => import('@/views/descriptor/pages/Quota/QuotaPage'),
      },
      {
        path: 'quota/:class_name',
        name: 'Quota',
        component: DescriptorPage,
      },
      {
        path: 'base',
        redirect: '/base/cards',
        name: 'Base',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: 'cards',
            name: 'Cards',
            component: Cards,
          },
          {
            path: 'forms',
            name: 'Forms',
            component: Forms,
          },
          {
            path: 'switches',
            name: 'Switches',
            component: Switches,
          },
          {
            path: 'tables',
            name: 'Tables',
            component: Tables,
          },
          {
            path: 'tabs',
            name: 'Tabs',
            component: Tabs,
          },
          {
            path: 'breadcrumbs',
            name: 'Breadcrumbs',
            component: Breadcrumbs,
          },
          {
            path: 'carousels',
            name: 'Carousels',
            component: Carousels,
          },
          {
            path: 'collapses',
            name: 'Collapses',
            component: Collapses,
          },
          {
            path: 'jumbotrons',
            name: 'Jumbotrons',
            component: Jumbotrons,
          },
          {
            path: 'list-groups',
            name: 'List Groups',
            component: ListGroups,
          },
          {
            path: 'navs',
            name: 'Navs',
            component: Navs,
          },
          {
            path: 'navbars',
            name: 'Navbars',
            component: Navbars,
          },
          {
            path: 'paginations',
            name: 'Paginations',
            component: Paginations,
          },
          {
            path: 'popovers',
            name: 'Popovers',
            component: Popovers,
          },
          {
            path: 'progress-bars',
            name: 'Progress Bars',
            component: ProgressBars,
          },
          {
            path: 'tooltips',
            name: 'Tooltips',
            component: Tooltips,
          },
        ],
      },
      {
        path: 'buttons',
        redirect: '/buttons/standard-buttons',
        name: 'Buttons',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: 'standard-buttons',
            name: 'Standard Buttons',
            component: StandardButtons,
          },
          {
            path: 'button-groups',
            name: 'Button Groups',
            component: ButtonGroups,
          },
          {
            path: 'dropdowns',
            name: 'Dropdowns',
            component: Dropdowns,
          },
          {
            path: 'brand-buttons',
            name: 'Brand Buttons',
            component: BrandButtons,
          },
        ],
      },
      {
        path: 'icons',
        redirect: '/icons/font-awesome',
        name: 'Icons',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: 'coreui-icons',
            name: 'CoreUI Icons',
            component: CoreUIIcons,
          },
          {
            path: 'flags',
            name: 'Flags',
            component: Flags,
          },
          {
            path: 'font-awesome',
            name: 'Font Awesome',
            component: FontAwesome,
          },
          {
            path: 'simple-line-icons',
            name: 'Simple Line Icons',
            component: SimpleLineIcons,
          },
        ],
      },
      {
        path: '/daily_route_confirmed_detail', // Non-nested routes must include a leading slash character (/).
        name: 'DailyRouteConfirmedDetail',
        component: () =>
          import ('@/views/descriptor/classes/DailyRouteConfirmed/DailyRouteConfirmedDetail'),
      },
      {
        path: '/daily_route_confirmed_detail/:id', // Non-nested routes must include a leading slash character (/).
        name: 'DailyRouteConfirmedDetail',
        component: () =>
          import ('@/views/descriptor/classes/DailyRouteConfirmed/DailyRouteConfirmedDetail'),
      },
      {
        path: 'notifications',
        redirect: '/notifications/alerts',
        name: 'Notifications',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: 'alerts',
            name: 'Alerts',
            component: Alerts,
          },
          {
            path: 'badges',
            name: 'Badges',
            component: Badges,
          },
          {
            path: 'modals',
            name: 'Modals',
            component: Modals,
          },
        ],
      },
      {
        path: '/reassign_account_manager_for_account', // Non-nested routes must include a leading slash character (/).
        name: 'Reassign AM Tool',
        component: () =>
            import ('@/views/descriptor/bulkUpdate/ReassignAccountManagerForAccount'),
      },
      {
        path: '/update_pdf_of_invoice_filtered', // Non-nested routes must include a leading slash character (/).
        name: 'Bulk Refresh Invoice PDF',
        component: () =>
            import ('@/views/descriptor/bulkUpdate/UpdatePdfOfInvoiceFiltered'),
      },
      {
        path: '/update_invoice_date_of_invoice_filtered', // Non-nested routes must include a leading slash character (/).
        name: 'Bulk Update Invoice Date',
        component: () =>
            import ('@/views/descriptor/bulkUpdate/UpdateInvoiceDateOfInvoiceFiltered.vue'),
      },
    ],
  },
  {
    path: '/daily_route_confirmed_print', // Non-nested routes must include a leading slash character (/).
    name: 'DailyRouteConfirmedPrint',
    component: () =>
        import ('@/views/descriptor/classes/DailyRouteConfirmed/DailyRouteConfirmedPrint'),
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: Page404,
      },
      {
        path: '500',
        name: 'Page500',
        component: Page500,
      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          is_public: true,
        },
      },
      {
        path: 'register',
        name: 'Register',
        component: Register,
      },
    ],
  },
  {
    path: '/:slug', // Non-nested routes must include a leading slash character (/).
    name: 'NotFound',
    component: Page404,
  },
];

export default routes;
