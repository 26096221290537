import axios from 'axios';
import _ from 'lodash';

export default function(Vue, options) {
  let router = options.router;
  let login_path = options.login_path;
  let api_root = options.api_root;
  let api_timeout = options.api_timeout;

  if (!router) {
    console.error('router instance required, to redirect to login page');
  }
  if (!login_path) {
    console.error('login_path required, to redirect to login page');
  }
  if (!api_root || !api_timeout) {
    console.error('api_root && api_timeout required');
  }

  let axiosInstance = axios.create({
    baseURL: api_root,
    timeout: api_timeout,
  });

  //Add authorization header for all requests
  axiosInstance.interceptors.request.use(async config => {
    if (Vue.prototype.$auth) {
      let access_token = Vue.prototype.$auth.access_token;
      let force_get_token = true; //TODO: need to check timestamp to see if token in ram is close to expiry, and then try to get from storage, to avoid geting constantly logged out when using multiple tabs
      if (!access_token || force_get_token) {
        access_token = await Vue.prototype.$auth.getToken();
      }
      if (access_token) {
        config.headers['Authorization'] = 'Bearer ' + access_token;
      } else {
        delete config.headers['Authorization'];
      }

      let server = Vue.prototype.$auth.server;
      if (!server) {
        server = await Vue.prototype.$auth.getServer();
      }
      if (server) {
        //override
        config.baseURL = server.url;
      } else {
        //use default
      }

      // const ENABLE_XDEBUG_PROFILE = true
      const ENABLE_XDEBUG_PROFILE = false
      if (ENABLE_XDEBUG_PROFILE) {
        config.params = { ...config.params, XDEBUG_PROFILE: 1 }; //ref: https://stackoverflow.com/a/61499640/3553367
        // config.headers['X-Xdebug-Profile-Filename'] = 'index.php'; //ref: https://xdebug.org/docs/profiler
      }

      // const ENABLE_LARAVEL_DB_PROFILE = true
      const ENABLE_LARAVEL_DB_PROFILE = false //not recommended to use https://github.com/dmitry-ivanov/laravel-db-profiler for api requestd as it reponds in HTML
      if (ENABLE_LARAVEL_DB_PROFILE) {
        config.params = { ...config.params, vvv: 1 };
      }
    }
    return config;
  });

  // Add a response interceptor, to check for unauthenticated errors
  axiosInstance.interceptors.response.use(
    response => {
      // success callback
      return response;
    },
    axios_error => {
      // error callback

      let has_auth_error = false;

      let short_error = _.get(axios_error, ['response', 'data', 'error']);

      let exception_message = _.get(axios_error, ['response', 'data', 'message']);

      if (short_error && (short_error.substring(0, 6) == 'token_' || short_error.substring(0, 5) == 'user_')) {
        has_auth_error = true;
      } else if (exception_message && exception_message.substring(0, 5) == 'Token') {
        has_auth_error = true;
      } else {
        var error = axiosInstance.getError(axios_error);
        if (error) {
          if (error.type == 'authentication' || error.type.substring(0, 6) == 'token_' || error.type.substring(0, 5) == 'user_') {
            //any error related to token
            has_auth_error = true;
          }
        }
      }

      if (has_auth_error) {
        console.warn('API authentication failed, jump to login page');
        if (router.history.current.path != login_path) {
          router.push(login_path);
        }
      }

      return Promise.reject(axios_error);
    }
  );

  axiosInstance.defaultErrorMessage = 'Unknown error, contact system administrator';

  axiosInstance.getData = function(response) {
    if (typeof response.data === 'object' && response.data) {
      var json = response.data;
      if (json.data) {
        return json.data;
      }
    }
    return;
  };

  axiosInstance.getError = function(error) {
    if (error.response && typeof error.response.data === 'object') {
      var json = error.response.data;
      var output = json.errors;
      if (output) {
        output.type = output.code;
        return output;
      }
    }
    return;
  };

  axiosInstance.getValidation = function(error) {
    var html = '';

    if (error.type == 'validation') {
      var errorMessages = [];

      _.forEach(error.meta.validator_errors, function(array) {
        //console.log(value);
        errorMessages = _.concat(errorMessages, array);
      });

      errorMessages = _.flatten(errorMessages);
      console.log(errorMessages);

      _.forEach(errorMessages, function(message, index) {
        if (index != 0) {
          html += '</br>';
        }
        html += message;
      });

      return html;
    } else {
      return;
    }
  };

  // ref: https://github.com/axios/axios/issues/1361#issuecomment-404380290
  let global_prev_source;

  axiosInstance.once = function(config = {}, local_prev_source, setSource) {
    //if without local_prev_source & setSource callback, will use global source

    let prev_source = null;
    if (local_prev_source) {
      prev_source = local_prev_source;
    } else {
      prev_source = global_prev_source;
    }

    if (prev_source) {
      if (prev_source && prev_source.cancel) {
        //if already have a previous source,
        prev_source.cancel(); //cancel that previous source
      }
    }

    let new_source = axios.CancelToken.source(); //re-create a new source & save
    config.cancelToken = new_source.token; //assign the source to the request

    if (setSource) {
      setSource(new_source);
    } else {
      if (!local_prev_source) {
        global_prev_source = new_source;
      }
    }

    return axiosInstance(config);
  };

  axiosInstance.isCancel = axios.isCancel;

  if (!Vue.prototype.$api) {
    Object.defineProperties(Vue.prototype, {
      $api: {
        get() {
          return axiosInstance;
        },
      },
    });
  } else {
    console.error('Another $api has been defined, will skip this one');
  }
}
