import Vue from 'vue';
import _ from 'lodash';
var moment = require('moment');

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');

//Pages
const DescriptorPage = () => import('@/descriptor/coreui/DescriptorPage');

const WrapperComponent = () => import('@/containers/WrapperComponent');

const AccountDescriptorCard = () => import('@/views/descriptor/classes/Account/AccountDescriptorCard');
const OrderDescriptorCard = () => import('@/views/descriptor/classes/Order/OrderDescriptorCard');
const QuotationDescriptorCard = () => import('@/views/descriptor/classes/Quotation/QuotationDescriptorCard');
const InvoiceDescriptorCard = () => import('@/views/descriptor/classes/Invoice/InvoiceDescriptorCard');

const routes = [
  {
    path: '/user',
    redirect: '/user/',
    name: 'User',
    component: DefaultContainer,
    children: [
      {
        path: '/user/:route_category?/:class_name',
        component: WrapperComponent,
        // component: import('@/views/descriptor/classes/Invoice/InvoiceDescriptorCard'),
        props: route => {
          let class_name = route.params['class_name'];

          let component = DescriptorPage

          let user = Vue.prototype.$auth.user;

          var user_relationship_name = null
          var account_manager_relationship_name = null
          var salesperson_relationship_name = null
          var driver_relationship_name = null
          var assistant_driver_relationship_name = null
          var technician_relationship_name = null

          let fixed_params = {
            // Vue.prototype.$d.relationFixedParams('Order', 'IncludeProducts', products),
            // Vue.prototype.$d.relationFixedParams('Order', 'IncludeOrderPackages', order_packages),
            // Vue.prototype.$d.relationFixedParams('Order', 'ClaimVouchers', vouchers),
          }
          let index_params = {}
          let or_filters = []

          let custom_props = {};

          let allow_absent = true;

          if (class_name == 'AccountManagerOrderCommissionGroupPending' || class_name == 'AccountManagerOrderCommissionGroup') {
            account_manager_relationship_name = 'ForAccountManager'
            custom_props = Object.assign(custom_props, {
              enable_add: false,
              _immutable: true,
            })
          } else if (class_name == 'AccManServiceOrderDoneCommissionGroupPending' || class_name == 'AccManServiceOrderDoneCommissionGroup') {
            account_manager_relationship_name = 'ForAccountManager'
            custom_props = Object.assign(custom_props, {
              enable_add: false,
              _immutable: true,
            })
          } else if (class_name == 'TechnicianServiceOrderDoneCommissionGroupPending' || class_name == 'TechnicianServiceOrderDoneCommissionGroup') {
            technician_relationship_name = 'ForTechnician'
            custom_props = Object.assign(custom_props, {
              enable_add: false,
              _immutable: true,
            })
          } else if (class_name == 'SalespersonOrderCommissionGroupPending' || class_name == 'SalespersonOrderCommissionGroup') {
            salesperson_relationship_name = 'ForSalesperson'
            custom_props = Object.assign(custom_props, {
              enable_add: false,
              _immutable: true,
            })
          } else if (class_name == 'DriverRtrCommissionGroupPending' || class_name == 'DriverRtrCommissionGroup') {
            driver_relationship_name = 'ForDriver'
            custom_props = Object.assign(custom_props, {
              enable_add: false,
              _immutable: true,
            })
          } else if (class_name == 'AssDriverRtrCommissionGroupPending' || class_name == 'AssDriverRtrCommissionGroup') {
            assistant_driver_relationship_name = 'ForAssistantDriver'
            custom_props = Object.assign(custom_props, {
              enable_add: false,
              _immutable: true,
            })
          } else if (class_name == 'DriverOrderDeliveredCommissionGroupPending' || class_name == 'DriverOrderDeliveredCommissionGroup') {
            driver_relationship_name = 'ForDriver'
            custom_props = Object.assign(custom_props, {
              enable_add: false,
              _immutable: true,
            })
          } else if (class_name == 'AssDriverOrderDeliveredCommissionGroupPending' || class_name == 'AssDriverOrderDeliveredCommissionGroup') {
            assistant_driver_relationship_name = 'ForAssistantDriver'
            custom_props = Object.assign(custom_props, {
              enable_add: false,
              _immutable: true,
            })
          } else if (class_name == 'BillingAccount') {
            // account_manager_relationship_name = 'CurrentlyManagedBy' //comment to temporarily allow full access until AccountManager setting is cleaned up
            //TODO: Salesperson Too?
          } else if (class_name == 'Account') {
            component = AccountDescriptorCard
            // account_manager_relationship_name = 'CurrentlyManagedBy' //comment to temporarily allow full access until AccountManager setting is cleaned up
            custom_props = Object.assign(custom_props, {
              _default_properties_sort: [{
                property_key: 'created_at',
                direction: "desc"
              }]
            })
            //TODO: Salesperson Too?
          } else if (class_name == 'CallScheduled') {
            salesperson_relationship_name = 'BySalesperson'
            //TODO: AccountManager Too?
          } else if (class_name == 'Event') {
            user_relationship_name = 'ByUser'
          } else if (class_name == 'Quotation') {
            component = QuotationDescriptorCard
            salesperson_relationship_name = 'BySalesperson'
            //TODO: AccountManager Too?
          } else if (class_name == 'Order') {
            component = OrderDescriptorCard
            account_manager_relationship_name = 'ManagedBy'
            salesperson_relationship_name = 'BySalesperson'
            custom_props = Object.assign(custom_props, {
              enable_add: false
            })
          } else if (class_name == 'ReplenishmentOrder') {
            salesperson_relationship_name = 'BySalesperson'
            //TODO: AccountManager Too?
          } else if (class_name == 'Invoice') {
            user_relationship_name = 'InvoicedBy'
            component = InvoiceDescriptorCard
          } else if (class_name == 'CreditNote') {
            user_relationship_name = 'IssuedBy'
          }

          ///

          if (user_relationship_name) {
            fixed_params = _.merge(fixed_params, Vue.prototype.$d.relationFixedParams(class_name, user_relationship_name, user ? [user] : []))
            or_filters.push(
              {
                existence: 'present',
                filter_type: 'relationship',
                // 'direction'         : 'from/to',
                from_class_name: class_name,
                relationship_name: user_relationship_name,
                filters: [
                  {
                    existence: 'present',
                    filter_type: 'property',
                    property_key: 'id',
                    equal: user['id'],
                  },
                ],
              })
            if (allow_absent) {
              or_filters.push(
                {
                  existence: 'absent', //NOTE: so that if data completely does not have the relationship, it will show too (avoid data disappearing)
                  filter_type: 'relationship',
                  // 'direction'         : 'from/to',
                  from_class_name: class_name,
                  relationship_name: user_relationship_name,
                })
            }
          }

          if (account_manager_relationship_name) {
            let account_manager = _.first(user[Vue.prototype.$d.getRelationAliasByNames('AccountManager', 'WithUser')]); //NOTE: This depends on Login component to request
            fixed_params = _.merge(fixed_params, Vue.prototype.$d.relationFixedParams(class_name, account_manager_relationship_name, account_manager ? [account_manager] : []))
            or_filters.push(
              {
                existence: 'present',
                filter_type: 'relationship',
                // 'direction'         : 'from/to',
                from_class_name: class_name,
                relationship_name: account_manager_relationship_name,
                filters: [
                  {
                    existence: 'present',
                    filter_type: 'relationship',
                    // 'direction'         : 'from/to',
                    from_class_name: 'AccountManager',
                    relationship_name: 'WithUser',
                    filters: [
                      {
                        existence: 'present',
                        filter_type: 'property',
                        property_key: 'id',
                        equal: user['id'],
                      },
                    ],
                  },
                ],
              })
            if (allow_absent) {
              or_filters.push(
                {
                  existence: 'absent', //NOTE: so that if data completely does not have the relationship, it will show too (avoid data disappearing)
                  filter_type: 'relationship',
                  // 'direction'         : 'from/to',
                  from_class_name: class_name,
                  relationship_name: account_manager_relationship_name,
                })
            }
          }

          if (salesperson_relationship_name) {
            let salesperson = _.first(user[Vue.prototype.$d.getRelationAliasByNames('Salesperson', 'WithUser')]); //NOTE: This depends on Login component to request
            fixed_params = _.merge(fixed_params, Vue.prototype.$d.relationFixedParams(class_name, salesperson_relationship_name, salesperson ? [salesperson] : []))
            or_filters.push(
              {
                existence: 'present',
                filter_type: 'relationship',
                // 'direction'         : 'from/to',
                from_class_name: class_name,
                relationship_name: salesperson_relationship_name,
                filters: [
                  {
                    existence: 'present',
                    filter_type: 'relationship',
                    // 'direction'         : 'from/to',
                    from_class_name: 'Salesperson',
                    relationship_name: 'WithUser',
                    filters: [
                      {
                        existence: 'present',
                        filter_type: 'property',
                        property_key: 'id',
                        equal: user['id'],
                      },
                    ],
                  },
                ],
              })
            if (allow_absent) {
              or_filters.push(
                {
                  existence: 'absent', //NOTE: so that if data completely does not have the relationship, it will show too (avoid data disappearing)
                  filter_type: 'relationship',
                  // 'direction'         : 'from/to',
                  from_class_name: class_name,
                  relationship_name: salesperson_relationship_name,
                })
            }
          }

          if (driver_relationship_name) {
            let driver = _.first(user[Vue.prototype.$d.getRelationAliasByNames('Driver', 'WithUser')]); //NOTE: This depends on Login component to request
            fixed_params = _.merge(fixed_params, Vue.prototype.$d.relationFixedParams(class_name, driver_relationship_name, driver ? [driver] : []))
            or_filters.push(
              {
                existence: 'present',
                filter_type: 'relationship',
                // 'direction'         : 'from/to',
                from_class_name: class_name,
                relationship_name: driver_relationship_name,
                filters: [
                  {
                    existence: 'present',
                    filter_type: 'relationship',
                    // 'direction'         : 'from/to',
                    from_class_name: 'Driver',
                    relationship_name: 'WithUser',
                    filters: [
                      {
                        existence: 'present',
                        filter_type: 'property',
                        property_key: 'id',
                        equal: user['id'],
                      },
                    ],
                  },
                ],
              })
            if (allow_absent) {
              or_filters.push(
                {
                  existence: 'absent', //NOTE: so that if data completely does not have the relationship, it will show too (avoid data disappearing)
                  filter_type: 'relationship',
                  // 'direction'         : 'from/to',
                  from_class_name: class_name,
                  relationship_name: driver_relationship_name,
                })
            }
          }

          if (assistant_driver_relationship_name) {
            let assistant_driver = _.first(user[Vue.prototype.$d.getRelationAliasByNames('AssistantDriver', 'WithUser')]); //NOTE: This depends on Login component to request
            fixed_params = _.merge(fixed_params, Vue.prototype.$d.relationFixedParams(class_name, assistant_driver_relationship_name, assistant_driver ? [assistant_driver] : []))
            or_filters.push(
              {
                existence: 'present',
                filter_type: 'relationship',
                // 'direction'         : 'from/to',
                from_class_name: class_name,
                relationship_name: assistant_driver_relationship_name,
                filters: [
                  {
                    existence: 'present',
                    filter_type: 'relationship',
                    // 'direction'         : 'from/to',
                    from_class_name: 'AssistantDriver',
                    relationship_name: 'WithUser',
                    filters: [
                      {
                        existence: 'present',
                        filter_type: 'property',
                        property_key: 'id',
                        equal: user['id'],
                      },
                    ],
                  },
                ],
              })
            if (allow_absent) {
              or_filters.push(
                {
                  existence: 'absent', //NOTE: so that if data completely does not have the relationship, it will show too (avoid data disappearing)
                  filter_type: 'relationship',
                  // 'direction'         : 'from/to',
                  from_class_name: class_name,
                  relationship_name: assistant_driver_relationship_name,
                })
            }
          }

          if (technician_relationship_name) {
            let technician = _.first(user[Vue.prototype.$d.getRelationAliasByNames('Technician', 'WithUser')]); //NOTE: This depends on Login component to request
            fixed_params = _.merge(fixed_params, Vue.prototype.$d.relationFixedParams(class_name, technician_relationship_name, technician ? [technician] : []))
            or_filters.push(
              {
                existence: 'present',
                filter_type: 'relationship',
                // 'direction'         : 'from/to',
                from_class_name: class_name,
                relationship_name: technician_relationship_name,
                filters: [
                  {
                    existence: 'present',
                    filter_type: 'relationship',
                    // 'direction'         : 'from/to',
                    from_class_name: 'Technician',
                    relationship_name: 'WithUser',
                    filters: [
                      {
                        existence: 'present',
                        filter_type: 'property',
                        property_key: 'id',
                        equal: user['id'],
                      },
                    ],
                  },
                ],
              })
            if (allow_absent) {
              or_filters.push(
                {
                  existence: 'absent', //NOTE: so that if data completely does not have the relationship, it will show too (avoid data disappearing)
                  filter_type: 'relationship',
                  // 'direction'         : 'from/to',
                  from_class_name: class_name,
                  relationship_name: technician_relationship_name,
                })
            }
          }

          index_params = Object.assign(index_params, {
            // with_virtual_properties: [],
            // with_virtual_properties: true,
            filters: [{
              operator: 'or',
              filter_type: 'expression',
              filters: or_filters,
            }],
          })

          let props = {
            component: component,
            // enable_add: false,
            // _immutable: true,
            fixed_params: fixed_params,
            _index_params: index_params,
          }

          props = Object.assign(props, custom_props)

          return props

        },
      },

    ],
  },
];

export default routes;
