// import something here
// import Vue from 'vue'
import localForage from 'localforage';
import _ from 'lodash';
import moment from 'moment';

var descriptorForage = localForage.createInstance({
  name: 'descriptorForage',
});

// leave the export, even if you don't use it
export default function(Vue, options) {
  // something to do

  let router = options.router;
  // let store = options.store

  if (!router) {
    console.error('router instance required, to get current route path');
  }

  const descriptorVue = new Vue({
    data() {
      return {
        isInit: false, //TODO: make this internal, cannot be changed be outer
        introspect: null,
        datetime: {
          offset: null,
          precision: null,
        },
      };
    },
    computed: {
      is_init() {
        return this.isInit;
      },
      is_synced() {
        if (this.datetime && this.datetime.offset && this.datetime.precision) {
          return true;
        }
        return false;
      },
      date_format() {
        return 'YYYY-MM-DD';
      },
      time_format() {
        // return 'HH:mm:ss';
        return 'hh:mm:ss A'; //need to go down to seconds, otherwise it might have a 1 minute hole/gap for time ranges
        // return 'hh:mm A';
        //TODO: a known bug in vue-ctk-date-time-picker if 'PM' is clicked twice: https://github.com/chronotruck/vue-ctk-date-time-picker/issues/196
      },
      datetime_format() {
        // return 'HH:mm:ss';
        // return this.date_format + ' ' + this.time_format;
        return this.date_format + ' [--] ' + this.time_format;
        // return this.date_format + ' [at] ' + this.time_format;
      },
    },
    created() {
      this.init();
    },
    methods: {
      removeAllData() {
        descriptorForage.clear();
        this.introspect = null;
      },

      removeData(key, successCallback) {
        descriptorForage.removeItem(key, () => {
          if (successCallback) {
            successCallback();
          }
        });
      },

      saveData(key, value, successCallback) {
        // console.log(key, value)
        descriptorForage.setItem(key, value, error => {
          if (!error) {
            if (successCallback) {
              successCallback();
            }
          } else {
            console.error(error);
          }
        });
      },

      getData(key, successCallback, errorCallback) {
        descriptorForage.getItem(key, (error, value) => {
          if (!error) {
            if (successCallback) {
              //still calls callback even if value is empty
              successCallback(value);
            }
          } else {
            if (errorCallback) {
              errorCallback(error);
            }
          }
        });
      },

      setCache(key, value, successCallback) {
        let timestamp = Date.now();
        key = 'cache:' + key;
        let cache_obj = {
          timestamp: timestamp,
          // max_age: max_age,
          value: value,
        };
        return this.saveData(key, cache_obj, successCallback);
      },

      objHash(obj) {
        //not sure if this is the proper way to hash objects

        //convert obj to string
        let str = JSON.stringify(obj);

        //ref: https://stackoverflow.com/a/7616484/3553367
        var hash = 0,
          i,
          chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
          chr = str.charCodeAt(i);
          hash = (hash << 5) - hash + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
      },

      getCache(key, successCallback, errorCallback) {
        key = 'cache:' + key;
        return this.getData(
          key,
          cache_obj => {
            let value = null;
            let cache_age = null;
            if (cache_obj) {
              cache_age = Date.now() - cache_obj.timestamp;
              value = cache_obj.value;

              /* if (!_.isNil(max_age)) {
                if (cache_age <= cache_obj.max_age) {
                  //override max age on get
                  value = cache_obj.value;
                }
              } else if (!_.isNil(cache_obj.max_age)) {
                //use default max age when set
                if (cache_age <= cache_obj.max_age) {
                  value = cache_obj.value;
                }
              } else {
                //no max age defined
                value = cache_obj.value;
              } */
            }

            if (successCallback) {
              //still calls callback even if value is empty
              successCallback(value, cache_age);
            }
          },
          errorCallback
        );
      },

      savePathData(key, value, successCallback) {
        key = 'path:' + router.history.current.path + '_' + key;
        return this.saveData(key, value, successCallback);
      },

      getPathData(key, successCallback, errorCallback) {
        key = 'path:' + router.history.current.path + '_' + key;
        return this.getData(key, successCallback, errorCallback);
      },

      removePathData(key, successCallback) {
        key = 'path:' + router.history.current.path + '_' + key;
        return this.removeData(key, successCallback);
      },

      saveDataForPath(path, key, value, successCallback) {
        key = 'path:' + path + '_' + key;
        return this.saveData(key, value, successCallback);
      },

      getDataFromPath(path, key, successCallback, errorCallback) {
        key = 'path:' + path + '_' + key;
        return this.getData(key, successCallback, errorCallback);
      },

      getIntrospect: async function(cachedCB = null, newestCB = null) {
        if (cachedCB) {
          if (this.introspect && typeof this.introspect == 'object') {
            cachedCB(this.introspect);
          } else {
            var stored_introspect = await descriptorForage.getItem('introspect');
            if (!stored_introspect || typeof stored_introspect != 'object') {
              // await descriptorForage.removeItem('introspect')
              descriptorForage.removeItem('introspect');
              this.introspect = null;
            } else {
              this.introspect = stored_introspect;
              cachedCB(this.introspect);
            }
          }
        }

        if (newestCB) {
          let error_message = null;
          let newest_introspect = null;
          Vue.prototype.$api
            .get('/descriptor/introspect')
            .then(response => {
              // success callback
              var data = Vue.prototype.$api.getData(response);
              if (data) {
                newest_introspect = data;
                this.introspect = newest_introspect;
                this.introspect.server = this.$auth.server; //save source of instropect, used to clear if not the same server //TODO: it's weird to get from $auth instead of $api
                descriptorForage.setItem('introspect', this.introspect);
                // console.log(data)
                newestCB(data, null);
              } else {
                newestCB(null, 'API Response format is invalid');
              }
            })
            .catch(axios_error => {
              // error callback
              var error = Vue.prototype.$api.getError(axios_error);
              if (error) {
                error_message = Vue.prototype.$api.getValidation(error);
                newestCB(null, error_message);
              } else {
                newestCB(null, error_message);
              }
            })
            .then(() => {
              if (!newest_introspect && !error_message) {
                error_message = Vue.prototype.$api.defaultErrorMessage;
                newestCB(null, error_message);
              }
            });
        }
      },

      getDatetimeNow: function(successCallback = null, errorCallback = null) {
        let error_message = null;
        let datetime_now = null;
        Vue.prototype.$api
          .get('/descriptor/datetime_now')
          .then(response => {
            // success callback
            var data = Vue.prototype.$api.getData(response);
            if (data) {
              datetime_now = data['datetime_now']; //TODO: accurate to only +-1 sec, because server returns only down to seconds

              if (datetime_now) {
                if (successCallback) {
                  successCallback(datetime_now);
                }
              } else {
                error_message = 'datetime_now not found';
              }
            }
          })
          .catch(axios_error => {
            // error callback
            var error = Vue.prototype.$api.getError(axios_error);
            if (error) {
              error_message = Vue.prototype.$api.getValidation(error);
            }
          })
          .then(() => {
            if (!datetime_now && !error_message) {
              error_message = Vue.prototype.$api.defaultErrorMessage;
            }

            if (error_message) {
              if (errorCallback) {
                errorCallback(error_message);
              }
            }
          });
      },

      syncDatetimeNow: function(successCallback = null, errorCallback = null) {
        descriptorForage.getItem('datetime').then(value => {
          // console.log(value)

          if (!value || typeof value != 'object') {
            // await descriptorForage.removeItem('datetime')
            descriptorForage.removeItem('datetime');
          } else {
            this.datetime = value;

            console.warn('Using Cached Datetime Sync');
            console.log('cached synced datetime:', this.momentFormat(this.momentSynced()));
          }
        });

        //ref: https://www.nodeguy.com/serverdate/ or https://github.com/NodeGuy/ServerDate
        let request_datetime = moment();
        this.getDatetimeNow(
          datetime_now => {
            let response_datetime = moment();

            this.datetime.precision = response_datetime.diff(request_datetime, 'ms') / 2; //calculate latency between client and server

            let actual_datetime_now = moment(datetime_now).add(this.datetime.precision, 'ms'); //compensate for latency

            this.datetime.offset = actual_datetime_now.diff(response_datetime);

            console.log('local datetime:', this.momentFormat(response_datetime));
            console.log('server datetime:', datetime_now);
            console.log('synced datetime:', this.momentFormat(this.momentSynced()));

            console.log('synced offset:', this.datetime.offset);
            console.log('synced precision:', this.datetime.precision);

            descriptorForage.setItem('datetime', this.datetime);

            if (successCallback) {
              successCallback(datetime_now);
            }
          },
          error_message => {
            if (errorCallback) {
              errorCallback(error_message);
            }
          }
        );
      },

      momentSynced: function(...args) {
        if (this.datetime.offset) {
          // return moment(...args) // do not sync time
          return moment(...args).add(this.datetime.offset, 'ms');
        }

        throw 'Datetime not synced yet!';
      },

      momentFormatDate: function(moment_obj) {
        return moment_obj.format('YYYY-MM-DD');
      },

      momentFormatTime: function(moment_obj) {
        return moment_obj.format('HH:mm:ss');
      },

      momentFormatDatetime: function(moment_obj) {
        return moment_obj.format('YYYY-MM-DD HH:mm:ss');
      },

      momentFormat: function(moment_obj) {
        return this.momentFormatDatetime(moment_obj);
      },

      init: async function(doneCB = null) {
        this.syncDatetimeNow();

        if (!this.checkInit(doneCB)) {
          // if (!this.introspect) {
          this.getIntrospect(
            stored_introspect => {
              // console.log('stored_introspect', stored_introspect);
              this.checkInit(doneCB);
            },
            (newest_introspect, error) => {
              if (newest_introspect) {
                // console.log('newest_introspect', newest_introspect);
              }
              if (error) {
                console.error(error);
              }
              this.checkInit(doneCB);
            }
          );
          // }
        }
      },

      checkInit(doneCB = null) {
        let isInit = true;
        if (this.introspect) {
          if (_.get(this.introspect, ['server', 'url']) !== _.get(this.$auth.server, ['url'])) {
            //if introspect from same url
            //check source of instropect, //TODO: it's weird to get from $auth instead of $api
            isInit = false;
          }
        } else {
          isInit = false;
        }

        this.isInit = isInit;
        if (doneCB) {
          doneCB();
        }
        return isInit;
      },

      getClass(class_name) {
        let class_ = null;
        if (!this.checkInit()) {
          throw 'Properly initialize Descriptor first!';
        } else {
          if (this.introspect.classes) {
            class_ = this.introspect.classes.find(class_ => {
              return class_['class_name'] == class_name;
            });
          }
        }

        if (!class_) {
          throw 'Invalid Class Name: ' + class_name;
        }
        return _.cloneDeep(class_);
      },

      isEqual(class_name, param1, param2) {
        // console.log('isEqual', param1, param2)

        let class_ = this.getClass(class_name);
        let properties = class_['properties'];

        for (let i = 0; i < properties.length; i++) {
          let property = properties[i];
          let property_key = property['property_key'];
          // console.log('comp: ', property_key, param1[property_key], param2[property_key])
          if (param1[property_key] !== param2[property_key]) {
            // console.log('diff: ', property_key, param1[property_key], param2[property_key]);
            return false;
          }
        }

        let to_relationships = this.getRelationships(class_name, 'to');

        for (let j = 0; j < to_relationships.length; j++) {
          let relationship = to_relationships[j];

          let relationship_alias = this.getRelationAlias(relationship);

          // let relation_ids1 = _.map(param1[relationship_alias], (relation_params) => {return relation_params['id']})
          // let relation_ids2 = _.map(param2[relationship_alias], (relation_params) => {return relation_params['id']})
          let diff1 = _.difference(param1[relationship_alias], param2[relationship_alias], 'id');
          let diff2 = _.difference(param2[relationship_alias], param1[relationship_alias], 'id');

          // console.log('comp: ', relationship_alias, param1[relationship_alias], param2[relationship_alias])
          if ((diff1 && diff1.length > 0) || (diff2 && diff2.length > 0)) {
            // console.log('diff: ', relationship_alias, param1[relationship_alias], param2[relationship_alias]);
            return false;
          }
        }

        // console.log('isEqulll!!')
        return true;
      },

      getClassTitle(class_name) {
        return _.startCase(class_name);
      },

      getClassTableColumns(class_name) {
        if (!this.checkInit()) {
          console.error('Properly initialize Descriptor first!');
        } else {
          if (!class_name || !this.introspect.classes) {
            return;
          }
          var class_ = this.getClass(class_name);
          var properties = class_['properties'];

          return this.getPropertyColumns(properties);
        }
        return null;
      },

      getProperties(class_name) {
        let class_ = this.getClass(class_name);
        return class_['properties'];
      },

      getProperty(properties, property_key) {
        return _.find(properties, property => {
          return property['property_key'] == property_key;
        });
      },

      filterDropdownRelationships(relationships) {
        return relationships.filter(relationship => {
          if (relationship['virtual']) {
            return false;
          }

          if (_.get(relationship, ['frontend', 'hidden_in_modal'])) {
            return false;
          }

          if (_.get(relationship, ['flags', 'dropdown']) == true) {
            return true;
          }

          if (_.get(relationship, ['to', 'frontend', 'mode']) == 'dropdown') {
            return true;
          }

          return false;
        });
      },

      getRelationship(from_class_name, relationship_name, is_probing = false) {
        if (!this.checkInit()) {
          console.error('Properly initialize Descriptor first!');
        } else {
          let relationship = this.introspect['relationships'].find(relationship => {
            return relationship['from']['class_name'] === from_class_name && relationship['relationship_name'] === relationship_name;
          });

          if (relationship) {
            return _.cloneDeep(relationship);
          } else {
            if (!is_probing) {
              throw `Relationship ${from_class_name} - ${relationship_name} not found`;
            }
          }
        }
      },

      getRelationships(priamry_class_name, relationship_direction, secondary_class_name = null) {
        if (!this.checkInit()) {
          console.error('Properly initialize Descriptor first!');
        } else {
          var relationship_direction_inverse = null;
          if (relationship_direction == 'from') {
            relationship_direction_inverse = 'to';
          } else if (relationship_direction == 'to') {
            relationship_direction_inverse = 'from';
          } else {
            console.error('relationship_direction must be either "from" or "to", ' + relationship_direction + 'given');
            return;
          }
          var relavantRelationships = [];
          this.introspect['relationships'].forEach(relationship => {
            var match = false;
            if (relationship[relationship_direction_inverse]['class_name'] == priamry_class_name) {
              if (secondary_class_name) {
                if (relationship[relationship_direction]['class_name'] == priamry_class_name) {
                  match = true;
                }
              } else {
                match = true;
              }
            }
            if (match) {
              relavantRelationships.push(_.merge(relationship));
            }
          });
          return _.cloneDeep(relavantRelationships);
        }

        return null;
      },

      relIsViewOnly(relationship) {
        let frontend_view_only = _.get(relationship, ['frontend', 'view_only'], _.get(relationship, ['to', 'frontend', 'view_only']));

        if (frontend_view_only == true) {
          return true;
        }

        if (_.get(relationship, ['mysql_view']) == true && frontend_view_only !== false) {
          return true;
        }

        if (_.get(relationship, ['subquery']) == true && frontend_view_only !== false) {
          return true;
        }

        return false;
      },

      relationshipIsSubquery(relationship) {
        if (_.get(relationship, ['subquery']) == true) {
          return true;
        }

        return false;
      },

      relationshipIsMysqlView(relationship) {
        if (_.get(relationship, ['mysql_view']) == true) {
          return true;
        }

        return false;
      },

      relationshipIsVirtual(relationship) {
        if (this.relationshipIsMysqlView(relationship)) {
          return true;
        }

        if (this.relationshipIsSubquery(relationship)) {
          return true;
        }

        return false;
      },

      propertyIsVirtual(property) {
        if (property['virtual_property']) {
          return true;
        }

        return false;
      },

      getRelationAlias(relationship, direction = null) {
        let prefix = '';
        if (direction) {
          if (direction !== 'to' && direction !== 'from') {
            throw 'Direction must be to or from';
          }
          prefix = direction + '_';
        }
        return prefix + relationship['from']['class_key'] + '_' + relationship['relationship_key'];
      },

      getRelationAliasByNames(from_class_name, relationship_name, direction = null) {
        let relationship = this.getRelationship(from_class_name, relationship_name);
        return this.getRelationAlias(relationship, direction);
      },

      getRelationshipFilterKey(relationship, direction = null) {
        return 'has_' + this.getRelationAlias(relationship, direction);
      },

      getRelationshipFilterKeyByName(from_class_name, relationship_name, direction = null) {
        let relationship = this.getRelationship(from_class_name, relationship_name);
        return 'has_' + this.getRelationAlias(relationship, direction);
      },

      getRelationPivotKey(relationship) {
        //TODO: should no longer access 'relationship_key' directly, use this instead
        return relationship['relationship_key'];
      },

      getRelationPivotKeyByName(from_class_name, relationship_name) {
        //TODO: should no longer access 'relationship_key' directly, use this instead
        let relationship = this.getRelationship(from_class_name, relationship_name);
        return this.getRelationPivotKey(relationship);
      },

      getRelationPivotProperties(relationship) {
        return relationship['properties'];
      },

      relationshipDirectionTowardsClass(from_relationship, to_class) {
        if (!to_class['class_name']) {
          throw 'to_class must be a Class, not just class_name';
        }

        if (from_relationship['from']['class_name'] == to_class['class_name'] && from_relationship['to']['class_name'] == to_class['class_name']) {
          throw 'Cannot automatically tell the direction, as Classes at both ends are the same';
        }

        if (from_relationship['from']['class_name'] == to_class['class_name']) {
          return 'from';
        } else if (from_relationship['to']['class_name'] == to_class['class_name']) {
          return 'to';
        } else {
          throw `The class and relationship: ${from_relationship['from']['class_name']} - ${from_relationship['relationship_name']} and ${
            to_class['class_name']
          } are incompatible`;
        }
      },

      relationshipDirectionAgainstClass(from_relationship, to_class) {
        let direction = this.relationshipDirectionTowardsClass(from_relationship, to_class);
        return this.invertRelationshipDirection(direction);
      },

      relationshipDirectionsBetweenRelationships(from_relationship, to_relationship) {
        let from_dir = null;
        let to_dir = null;

        if (from_relationship['to']['class_name'] == to_relationship['from']['class_name']) {
          from_dir = 'to';
          to_dir = 'from';
        } else if (from_relationship['from']['class_name'] == to_relationship['from']['class_name']) {
          from_dir = 'from';
          to_dir = 'from';
        } else if (from_relationship['to']['class_name'] == to_relationship['to']['class_name']) {
          from_dir = 'to';
          to_dir = 'to';
        } else if (from_relationship['from']['class_name'] == to_relationship['from']['class_name']) {
          from_dir = 'from';
          to_dir = 'from';
        } else {
          throw `The two relationships: ${from_relationship['from']['class_name']} - ${
            from_relationship['relationship_name']
          } and {to_relationship['from']['class_name']} - ${to_relationship['relationship_name']}  are incompatible`;
          // return false;
        }

        return {
          from_dir: from_dir,
          to_dir: to_dir,
        };
      },

      invertRelationshipDirection(relationship_direction) {
        let relationship_direction_inverse = null;
        if (relationship_direction === 'from') {
          relationship_direction_inverse = 'to';
        } else if (relationship_direction === 'to') {
          relationship_direction_inverse = 'from';
        } else {
          throw `The given relationship direction: ${JSON.stringify(relationship_direction)} is invalid, only "to" or "from" is accepted`;
        }

        return relationship_direction_inverse;
      },

      isDropdown(relationship) {
        if (_.get(relationship, ['flags', 'dropdown']) === true || _.get(relationship, ['flags', 'multi_dropdown']) === true) {
          return true;
        }
        return false;
      },

      downloadRequest(request_type, class_name, index_params = {}, successCallback = null, errorCallback = null, endCallback = null) {
        let extra_axios_config = {
          responseType: 'blob',
        };
        return this.request(
          request_type,
          class_name,
          index_params,
          downloaded_data => {
            // console.log(downloaded_data)
            //ref: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
            const url = window.URL.createObjectURL(new Blob([downloaded_data]));
            const link = document.createElement('a');
            link.href = url;
            let datetime = this.momentSynced().format('YYYYMMDD_HHmmss');
            link.setAttribute('download', class_name + '_' + datetime + '.xlsx');
            document.body.appendChild(link);
            link.click();

            if (successCallback) {
              successCallback();
            }
          },
          errorCallback,
          endCallback,
          extra_axios_config
        );
      },

      base64ToDataUrl(base64, mime_type) {
        function _base64ToArrayBuffer(base64) {
          //same code as in PdfView.vue
          let binary_string = window.atob(base64);
          let len = binary_string.length;
          let bytes = new Uint8Array(len);
          for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
          return bytes.buffer;
        }

        let file = new Blob([_base64ToArrayBuffer(base64)], {
          type: mime_type, // 'application/pdf',
        });
        return URL.createObjectURL(file);
      },

      request(
        request_type,
        class_name,
        index_params = {},
        successCallback = null,
        errorCallback = null,
        endCallback = null,
        extra_axios_config = {},
        local_prev_source,
        setSource
      ) {
        let error_message = null;

        let class_data = null;

        let temp_index_params = {};

        let default_index_params = {};

        if (request_type == 'index') {
          default_index_params = {
            page: 1,
            per_page: 'all',
          };
        }

        temp_index_params = Object.assign(temp_index_params, default_index_params);
        temp_index_params = Object.assign(temp_index_params, index_params);

        let is_cancelled = false;
        var config = {
          method: 'post',
          url: `/descriptor/${_.snakeCase(class_name)}/${request_type}`,
          data: temp_index_params,
        };

        config = Object.assign(config, extra_axios_config);

        let pagination = null;

        let apiInstance = null;
        if (setSource) {
          apiInstance = Vue.prototype.$api.once(config, local_prev_source, setSource);
        } else {
          apiInstance = Vue.prototype.$api(config);
        }

        apiInstance
          .then(response => {
            // success callback
            var data = this.$api.getData(response);
            if (data) {
              // console.log(data);
              //try several ways to get the data
              pagination = _.get(data, [`${_.snakeCase(class_name)}_pagination`]);

              if (pagination) {
                class_data = _.get(pagination, ['data']);
              }

              if (!class_data) {
                class_data = _.get(data, [`${_.snakeCase(class_name)}`]);
              }
              if (!class_data) {
                class_data = data; //worse case, most likely custom api
              }
            } else {
              class_data = response.data; //worse case, just give everything most likely for downloads
            }
          })
          .catch(axios_error => {
            // error callback
            var error = this.$api.getError(axios_error);
            if (error) {
              error_message = this.$api.getValidation(error);
            }

            if (this.$api.isCancel(axios_error)) {
              console.warn('Request cancelled');
              is_cancelled = true;
            } else if (!error_message) {
              error_message = this.$api.defaultErrorMessage;
            } else {
              console.error(axios_error);
            }
          })
          .then(() => {
            if (!is_cancelled) {
              if (class_data) {
                if (successCallback) {
                  successCallback(class_data, pagination);
                }
              }

              if (!class_data && !error_message) {
                error_message = this.$api.defaultErrorMessage;
              }

              if (error_message) {
                if (errorCallback) {
                  errorCallback(error_message, is_cancelled);
                }
              }
            }

            if (endCallback) {
              endCallback(class_data, error_message, is_cancelled);
            }
          });
      },

      batchRequest(batch_params = {}, successCallback = null, errorCallback = null, endCallback = null, extra_axios_config = {}, local_prev_source, setSource) {
        let result_data = null;
        let error_message = null;

        let is_cancelled = false;
        var config = {
          method: 'post',
          url: `/descriptor/batch`,
          data: batch_params,
        };

        config = Object.assign(config, extra_axios_config);

        let apiInstance = null;
        if (setSource) {
          apiInstance = Vue.prototype.$api.once(config, local_prev_source, setSource);
        } else {
          apiInstance = Vue.prototype.$api(config);
        }

        apiInstance
          .then(response => {
            // success callback
            result_data = this.$api.getData(response);
            if (!result_data) {
              result_data = response.data; //worse case, just give everything most likely for downloads
            }
          })
          .catch(axios_error => {
            // error callback
            var error = this.$api.getError(axios_error);
            if (error) {
              error_message = this.$api.getValidation(error);
            }

            if (this.$api.isCancel(axios_error)) {
              console.warn('Request cancelled');
              is_cancelled = true;
            } else if (!error_message) {
              error_message = this.$api.defaultErrorMessage;
            } else {
              console.error(axios_error);
            }
          })
          .then(() => {
            if (!is_cancelled) {
              if (result_data) {
                if (successCallback) {
                  successCallback(result_data);
                }
              }

              if (!result_data && !error_message) {
                error_message = this.$api.defaultErrorMessage;
              }

              if (error_message) {
                if (errorCallback) {
                  errorCallback(error_message, is_cancelled);
                }
              }
            }

            if (endCallback) {
              endCallback(result_data, error_message, is_cancelled);
            }
          });
      },

      mergeNonFilterParams(to_obj, index_params) {
        to_obj = _.merge(
          to_obj,
          _.omit(index_params, ['properties_filter', 'relationships_filter', 'or_properties_filters', 'or_relationships_filters', 'properties_sort', 'filters'])
        ); //thorough merge needed, for things like with_childs, with_relationships (anything not included in omit above)

        return to_obj;
      },

      mergeArray(to_array, from_array) {
        if (from_array) {
          if (!Array.isArray(to_array)) {
            to_array = [];
          }
          to_array = to_array.concat(from_array);
        }

        return to_array;
      },

      mergeObject(to_object, from_object) {
        if (from_object) {
          if (_.isNil(to_object) || !_.isObject(to_object)) {
            to_object = {};
          }

          to_object = _.merge(to_object, from_object);
        }

        return to_object;
      },

      //TODO: not tested yet
      mergeIndexParams(to_index_params, from_index_params) {
        if (_.isNil(to_index_params) || !_.isObject(to_index_params)) {
          to_index_params = {};
        }

        if (from_index_params) {
          to_index_params['with_relationships'] = this.mergeArray(to_index_params['with_relationships'], from_index_params['with_relationships']);
        }

        return to_index_params;
      },

      mergeFilterParams(to_obj, filter_params) {
        if (filter_params) {
          if (filter_params['properties_filter']) {
            if (_.isNil(to_obj['properties_filter']) || !_.isObject(to_obj['properties_filter'])) {
              to_obj['properties_filter'] = {};
            }

            to_obj['properties_filter'] = Object.assign(to_obj['properties_filter'], filter_params['properties_filter']);
          }

          if (filter_params['relationships_filter']) {
            if (!Array.isArray(to_obj['relationships_filter'])) {
              to_obj['relationships_filter'] = [];
            }

            to_obj['relationships_filter'] = to_obj['relationships_filter'].concat(filter_params['relationships_filter']);
          }

          if (filter_params['or_properties_filters']) {
            if (!Array.isArray(to_obj['or_properties_filters'])) {
              to_obj['or_properties_filters'] = [];
            }

            to_obj['or_properties_filters'] = to_obj['or_properties_filters'].concat(filter_params['or_properties_filters']);
          }

          if (filter_params['or_relationships_filters']) {
            if (!Array.isArray(to_obj['or_relationships_filters'])) {
              to_obj['or_relationships_filters'] = [];
            }

            to_obj['or_relationships_filters'] = to_obj['or_relationships_filters'].concat(filter_params['or_relationships_filters']);
          }

          if (filter_params['properties_sort']) {
            if (!Array.isArray(to_obj['properties_sort'])) {
              to_obj['properties_sort'] = [];
            }

            to_obj['properties_sort'] = to_obj['properties_sort'].concat(filter_params['properties_sort']);
          }

          if (filter_params['filters']) {
            if (!Array.isArray(to_obj['filters'])) {
              to_obj['filters'] = [];
            }

            to_obj['filters'] = to_obj['filters'].concat(filter_params['filters']);
          }
        }

        return to_obj;
      },

      isRuleConditionMet(params, rule_condition) {
        if (rule_condition == 'default') {
          return true;
        }

        if (rule_condition == 'not_archived') {
          if (_.isNil(params['archived_at'])) {
            return true;
          }
        }
        return false;
      },

      getRelationToSearch(relationship, params, default_value = null, direction) {
        console.log('getRelationToSearch', relationship, params, default_value, direction);
        // var for_relation_alias = this.getRelationAlias(relationship);

        let index_params = {};
        // let rules = Object.values(_.omit(relationship['to']['rules']['default'], ['min', 'max']));
        // console.log('relationship', relationship['relationship_name']);

        let rules_by_condition = relationship['to']['rules'];
        Object.keys(rules_by_condition).forEach(rule_condition => {
          // let condition_rules = rules_by_condition[rule_condition]
          let condition_rules = Object.values(_.omit(rules_by_condition[rule_condition], ['min', 'max']));

          // console.log('rule_condition', rule_condition);
          // console.log('condition_rules', condition_rules);

          let is_condition_met = this.isRuleConditionMet(params, rule_condition);
          // console.log('is_condition_met', params, is_condition_met);
          if (is_condition_met) {
            condition_rules.forEach(rule => {
              //has_relationship
              if (rule['rule_name'] == 'has_relationship') {
                let rule_relationship = this.getRelationship(rule['from_class_name'], rule['relationship_name']);
                let rule_relation_filter_params = _.get(rule, ['relation_filter_params'], {});

                let temp_filter_params = {};
                temp_filter_params['filters'] = [];
                temp_filter_params['filters'].push(
                  this.mergeFilterParams(
                    {
                      existence: 'present',
                      filter_type: 'relationship',
                      // 'direction'         : 'from/to',
                      from_class_name: rule['from_class_name'],
                      relationship_name: rule['relationship_name'],
                    },
                    rule_relation_filter_params
                  )
                );
                this.mergeFilterParams(index_params, temp_filter_params);
                // console.log('has_relationship: ', this.getRelationAlias(rule_relationship));
              }
              if (rule['rule_name'] == 'has_no_relationship') {
                let rule_relationship = this.getRelationship(rule['from_class_name'], rule['relationship_name']);
                let rule_relation_filter_params = _.get(rule, ['relation_filter_params'], {});

                let temp_filter_params = {};
                temp_filter_params['filters'] = [];
                temp_filter_params['filters'].push(
                  this.mergeFilterParams(
                    {
                      existence: 'absent',
                      filter_type: 'relationship',
                      // 'direction'         : 'from/to',
                      from_class_name: rule['from_class_name'],
                      relationship_name: rule['relationship_name'],
                    },
                    rule_relation_filter_params
                  )
                );
                this.mergeFilterParams(index_params, temp_filter_params);
                // console.log('has_no_relationship: ', this.getRelationAlias(rule_relationship));
              }

              if (rule['rule_name'] == 'has_no_relationship_except_self') {
                let rule_relationship = this.getRelationship(rule['from_class_name'], rule['relationship_name']);
                let rule_relation_filter_params = _.get(rule, ['relation_filter_params'], {});

                let temp_filter_params = {};
                temp_filter_params['filters'] = [];

                let temp_or_filters = [];

                temp_or_filters.push(
                  this.mergeFilterParams(
                    {
                      existence: 'absent',
                      filter_type: 'relationship',
                      // 'direction'         : 'from/to',
                      from_class_name: rule['from_class_name'],
                      relationship_name: rule['relationship_name'],
                    },
                    rule_relation_filter_params
                  )
                );

                if (params['id']) {
                  //not very brilliant as it will include no matter what
                  temp_or_filters.push(
                    this.mergeFilterParams(
                      {
                        existence: 'present',
                        filter_type: 'relationship',
                        // 'direction'         : 'from/to',
                        from_class_name: rule['from_class_name'],
                        relationship_name: rule['relationship_name'],
                        filters: [
                          {
                            existence: 'present',
                            filter_type: 'property',
                            property_key: 'id',
                            equal: params['id'],
                          },
                        ],
                      },
                      rule_relation_filter_params
                    )
                  );
                }

                temp_filter_params['filters'].push({
                  operator: 'or',
                  filter_type: 'expression',
                  filters: temp_or_filters,
                });

                this.mergeFilterParams(index_params, temp_filter_params);
                // console.log('has_no_relationship_except_self: ', this.getRelationAlias(rule_relationship), rule_relation_filter_params, params);
              }

              if (rule['rule_name'] == 'exists_after_filter') {
                var rule_filter_params = rule['filter_params'];
                this.mergeFilterParams(index_params, rule_filter_params);
                // console.log('exists_after_filter: ', rule_filter_params);
              }

              if (
                rule['rule_name'] == 'same_relation_as' ||
                rule['rule_name'] == 'same_relation_as_from_relation' ||
                rule['rule_name'] == 'same_relation_as_to_relation'
              ) {
                console.log(rule['rule_name']);
                if (rule['rule_name'] == 'same_relation_as' || rule['rule_name'] == 'same_relation_as_to_relation') {
                  var rule_from_relationship = this.getRelationship(
                    rule['from_relation_relationship']['from_class_name'],
                    rule['from_relation_relationship']['relationship_name']
                  );
                  var rule_from_relationship_dir = rule['from_relation_relationship']['direction'];
                }

                if (rule['rule_name'] == 'same_relation_as' || rule['rule_name'] == 'same_relation_as_from_relation') {
                  var rule_to_relationship = this.getRelationship(
                    rule['to_relation_relationship']['from_class_name'],
                    rule['to_relation_relationship']['relationship_name']
                  );
                  var rule_to_relationship_dir = rule['to_relation_relationship']['direction'];
                }

                var same_relation_as_operator = _.get(rule, ['operator'], 'any'); //defaults to 'any'

                if (same_relation_as_operator != 'any' && same_relation_as_operator != 'all') {
                  throw 'Invalid same_relation_as_operator';
                }

                let temp_filter_params = {};
                temp_filter_params['filters'] = [];

                let filter_key_param = null;

                if (rule['rule_name'] == 'same_relation_as' || rule['rule_name'] == 'same_relation_as_to_relation') {
                  let target_relation_alias = this.getRelationAlias(rule_from_relationship);

                  //TODO: params[target_relation_alias] will be undefined if it is a virtual relationship, especially when recursive, frontend may need to implement some of backend's relationship logic
                  /* console.log(
                    target_relation_alias,
                    params,
                    params[target_relation_alias] ? params[target_relation_alias].length : params[target_relation_alias]
                  ); */

                  if (params[target_relation_alias] && Array.isArray(params[target_relation_alias]) && params[target_relation_alias].length) {
                    // console.log('same_relation_as target: ', target_relation_alias, params[target_relation_alias]);
                    filter_key_param = [];
                    params[target_relation_alias].forEach(item => {
                      if (item) {
                        filter_key_param.push(item.id);
                      } else {
                        filter_key_param = default_value; //TODO: this essentially strips away the array of relation_ids, into true / null
                      }
                    });
                  } else {
                    console.error('same_relation_as target relation could not be found: ', params, target_relation_alias);
                    //NOTE: cannot be gotten directly
                    /* if (rule['rule_name'] == 'same_relation_as') { //TODO: causind issue for example DailyRouteAddressOrder - Select Order. Address not available
                      if (!rule_from_relationship_dir) {
                        rule_from_relationship_dir = this.$d.relationshipDirectionAgainstClass(rule_from_relationship, this.$d.getClass(relationship[this.$d.invertRelationshipDirection(direction)]['class_name']));
                      }
                      if (!rule_to_relationship_dir) {
                        rule_to_relationship_dir = this.$d.relationshipDirectionAgainstClass(rule_to_relationship, this.$d.getClass(relationship[direction]['class_name']));
                      }

                      temp_filter_params['filters'].push({
                        existence: 'present',
                        filter_type: 'relationship',
                        direction: rule_to_relationship_dir,
                        from_class_name: rule_to_relationship['from']['class_name'],
                        relationship_name: rule_to_relationship['relationship_name'],
                        filters: [
                          {
                            existence: 'present',
                            filter_type: 'relationship',
                            direction: this.$d.invertRelationshipDirection(rule_from_relationship_dir),
                            from_class_name: rule_from_relationship['from']['class_name'],
                            relationship_name: rule_from_relationship['relationship_name'],
                            filters: [
                              {
                                existence: 'present',
                                filter_type: 'property',
                                property_key: 'id',
                                equal: params['id'], //NOTE: very likely in the 'from' direction - relationship['from']['class_name']
                              },
                            ],
                          },
                        ],
                      });
                    }else{
                      console.error('unacle to handle this rule', rule); //TODO: need to greatly clean up the logic
                    } */
                  }
                }

                if (rule['rule_name'] == 'same_relation_as_from_relation') {
                  if (params['id']) {
                    filter_key_param = [params['id']];
                  } else {
                    filter_key_param = default_value;
                  }
                }

                // console.log('filter_key_param', rule['rule_name'], filter_key_param);

                //uncomment below to force using filter key
                // index_params[this.getRelationshipFilterKey(rule_to_relationship)] = filter_key_param; //TODO: completely retire the use of filter_key

                if (rule['rule_name'] == 'same_relation_as' || rule['rule_name'] == 'same_relation_as_from_relation') {
                  //convert filter_key_param into filters param

                  if (filter_key_param === true) {
                    //this means the relationship must be present

                    temp_filter_params['filters'].push({
                      existence: 'present',
                      filter_type: 'relationship',
                      // 'direction'         : 'from/to',
                      from_class_name: rule_to_relationship['from']['class_name'],
                      relationship_name: rule_to_relationship['relationship_name'],
                    });
                  } else if (filter_key_param === false) {
                    //this means the relationship must be absent

                    temp_filter_params['filters'].push({
                      existence: 'absent',
                      filter_type: 'relationship',
                      // 'direction'         : 'from/to',
                      from_class_name: rule_to_relationship['from']['class_name'],
                      relationship_name: rule_to_relationship['relationship_name'],
                    });
                  } else if (Array.isArray(filter_key_param)) {
                    //this means relationship must be present and IDs must be either one of those

                    /* temp_filter_params['filters'].push({
                      existence: 'present',
                      filter_type: 'relationship',
                      // 'direction'         : 'from/to',
                      from_class_name: rule_to_relationship['from']['class_name'],
                      relationship_name: rule_to_relationship['relationship_name'],
                      or_properties_filters: [
                        {
                          id: {
                            equal: filter_key_param, //just put the array in and assume it will use "OR"
                            existence: 'present',
                          },
                        },
                      ],
                    }); */

                    temp_filter_params['filters'].push({
                      operator: same_relation_as_operator == 'any' ? 'or' : 'and',
                      filter_type: 'expression',
                      filters: _.map(filter_key_param, filter_key_param_item => {
                        return {
                          existence: 'present',
                          filter_type: 'relationship',
                          // 'direction'         : 'from/to',
                          from_class_name: rule_to_relationship['from']['class_name'],
                          relationship_name: rule_to_relationship['relationship_name'],
                          filters: [
                            {
                              existence: 'present',
                              filter_type: 'property',
                              property_key: 'id',
                              equal: filter_key_param_item,
                            },
                          ],
                        };
                      }),
                    });
                  } else if (filter_key_param !== null) {
                    //fallback, don't know how to deal with the
                    index_params[this.getRelationshipFilterKey(rule_to_relationship)] = filter_key_param; //TODO: completely retire the use of filter_key
                  }
                }

                if (rule['rule_name'] == 'same_relation_as_to_relation') {
                  //convert filter_key_param into filters param
                  if (filter_key_param === true) {
                    //this means the property must be present

                    temp_filter_params['filters'].push({
                      existence: 'absent',
                      filter_type: 'property',
                      property_key: 'id',
                      equal: null,
                    });
                  } else if (filter_key_param === false) {
                    //this means the property must be absent

                    temp_filter_params['filters'].push({
                      existence: 'present',
                      filter_type: 'property',
                      property_key: 'id',
                      equal: null,
                    });
                  } else if (Array.isArray(filter_key_param)) {
                    //this means relationship must be present and IDs must be either one of those

                    /* temp_filter_params['filters'].push({
                      existence: 'present',
                      filter_type: 'property',
                      property_key: 'id',
                      equal: filter_key_param, //just put the array in and assume it will use "OR"
                    }); */

                    temp_filter_params['filters'].push({
                      operator: same_relation_as_operator == 'any' ? 'or' : 'and',
                      filter_type: 'expression',
                      filters: _.map(filter_key_param, filter_key_param_item => {
                        return {
                          existence: 'present',
                          filter_type: 'property',
                          property_key: 'id',
                          equal: filter_key_param_item,
                        };
                      }),
                    });
                  } else if (filter_key_param !== null) {
                    //probably invalid filter_key_param, just ignore, should probably throw an exception?
                  }
                }

                this.mergeFilterParams(index_params, temp_filter_params);
              }
            });
          }
        });

        // console.log('getRelationToSearch', relationship['from']['class_name'], relationship['relationship_name'], index_params);
        return index_params;
      },

      relationFixedParams(from_class_name, relationship_name, param) {
        let fixed_params = {};

        fixed_params[this.getRelationAliasByNames(from_class_name, relationship_name)] = param;

        return fixed_params;
      },

      relationIndexParams(from_class_name, relationship_name, id) {
        let index_params = {};

        index_params['filters'] = [];

        index_params['filters'].push({
          filter_type: 'relationship',
          existence: 'present',
          // 'direction'         : 'from/to',
          from_class_name: from_class_name,
          relationship_name: relationship_name,
          filters: [
            {
              filter_type: 'property',
              existence: 'present',
              property_key: 'id',
              equal: id,
            },
          ],
        });

        return index_params;
      },

      //PROJECT SPECIFIC CODE BELOW: //TODO: Split this to a separate file
      getPropertyColumns(properties) {
        var fields = _.map(properties, (property, index) => {
          // console.log(property, index)
          var format = val => {
            return val;
          };
          let field = row => {
            return _.get(row, property['property_key']);
          };

          if (property['type'] === 'array') {
            format = val => {
              return JSON.stringify(val);
            };
          }

          if (property['type'] === 'array_property') {
            var subproperty_key = property['array_property']['property_key'];
            // var subproperty_type = property['array_property']['type'];
            format = values => {
              // return values ? JSON.stringify(values.map(value => _.get(value, subproperty_key))) : null;
              return values ? values.map(value => _.get(value, subproperty_key)).join(', ') : null;
            };
          }

          return {
            name: `${property['property_key']}_${index}`,
            field: field,
            label: property['property_name'],
            format: format,
            align: 'left',
            sortable: false,
            property: property,
          };
        });

        return fields;
      },

      getDeepProperty(items, property, cache_by_property = {}) {
        // console.log(property)
        let property_key = property['property_key'];

        let property_path = null;
        /* if (!_.isEmpty(items[property_key])) {
                    return items[property_key]
                    property_path = property_key
                } */

        if (cache_by_property['property_path']) {
          //hacky way to cache property_path, avoids constantly stringify
          property_path = cache_by_property['property_path'];
        } else {
          property_path = property_key;

          if (property['virtual_property'] == true && property['virtual_property_path']) {
            //IMPORTANT TODO: VERY rudimentary way to detect if array should be returned may have some performance impact
            let stringified_virtual_property_path = JSON.stringify(property['virtual_property_path']);
            if (stringified_virtual_property_path.toLowerCase().includes('GROUP_CONCAT'.toLowerCase())) {
              if (!stringified_virtual_property_path.toLowerCase().includes('filters'.toLowerCase())) {
                property_path = property['virtual_property_path'];
              }
            }
          }

          // console.log(property_path)

          if (!property_path) {
            throw 'No property_path valid for getDeep';
          }
        }

        let deep_value = this.getDeep(items, property_path);

        // console.log(deep_value)

        let final_value = null;
        //prioritise virtual_property instead of from property_key
        if (!_.isEmpty(deep_value)) {
          final_value = deep_value;
        } else {
          if (property_key in items) {
            final_value = items[property_key];
          } else {
            final_value = undefined;
          }
        }

        // console.log(property_key, property_path, final_value);

        // console.log(final_value)

        return final_value;
      },

      getDeep(items, property_path) {
        // console.log('getDeep', items, property_path);
        if (Array.isArray(property_path)) {
          let next_property_path = [].concat(property_path); //clone
          let use_property_path = next_property_path.shift();
          next_property_path = [].concat(next_property_path); //clone

          // console.log('getDeep use_property_path', use_property_path);
          if (use_property_path) {
            //TODO: not sure why this is needed, maybe because some property_path is only 1 level deep
            let use_key = null;

            if (use_property_path['from_class_name'] && use_property_path['relationship_name']) {
              use_key = this.getRelationAliasByNames(use_property_path['from_class_name'], use_property_path['relationship_name']);

              if (use_property_path['property_key']) {
                //if property_key of relationship
                next_property_path.unshift([
                  //self add a property_path
                  this.getRelationPivotKeyByName(use_property_path['from_class_name'], use_property_path['relationship_name']),
                  use_property_path['property_key'],
                ]);
              }
            } else if (use_property_path['property_key']) {
              use_key = use_property_path['property_key'];
            } else {
              use_key = use_property_path;
            }

            // console.log('getDeep use_key', use_key);

            let next_items = _.get(items, use_key);
            // console.log('getDeep', items, next_items, next_property_path);
            // console.log('getDeep next_items', next_items);
            if (Array.isArray(next_items) && !_.isNil(next_property_path) && !_.isEmpty(next_property_path)) {
              // console.log('IS Array next_items', next_items)

              let concat_next_items = null;
              next_items.forEach(next_item => {
                // console.log('forEEach next_item', next_item);
                let new_next_items = this.getDeep(next_item, next_property_path);
                // console.log('new_next_items', new_next_items);
                if (!_.isNil(new_next_items)) {
                  if (concat_next_items == null) {
                    concat_next_items = [];
                  }
                  concat_next_items = concat_next_items.concat(new_next_items);
                }
              });
              // console.log('final concat_next_items', concat_next_items);
              return concat_next_items;
            } else {
              // console.log('end next_items', next_items);
              return next_items;
            }
          }
        }
        return _.get(items, property_path);
      },

      propertyPresenter(property, value) {
        let property_type = property['type'];
        let formatted_value = value;
        //TODO: same as the one in FormInput, create sanitizer/presenter function
        if (!_.isNil(value)) {
          if (property_type == 'datetime') {
            formatted_value = moment(value, this.datetime_format).format(this.datetime_format);
          }
          if (property_type == 'date') {
            formatted_value = moment(value, this.date_format).format(this.date_format);
          }
          if (property_type == 'time') {
            formatted_value = moment(value, this.time_format).format(this.time_format);
          }
          if (property_type == 'number') {
            //Add thousands comma from the string value passed from API
            const splitted = (value && value.split) ? value.split('.') : [value];
            const formattedFirstPart = parseFloat(splitted[0]) !== 'NaN' ? parseFloat(splitted[0]).toLocaleString('en-MY') : splitted[0];
            formatted_value = `${formattedFirstPart}${splitted[1] ? `.${splitted[1]}` : ''}`;
          }

          if (property_type == 'boolean') {
            //TODO: cannot do this here, because it actually changes the results, should be in html

            // formatted_value = value ? '\u25ef' : '\u2715';
            // formatted_value = value ? '\u2714' : '\u2718'; //check and cross
            formatted_value = value ? '\u26ab' : '\u25ef'; //filled circle and blank circle
            //for more symbols copy from: https://coolsymbol.com/
            //and convert to unicode: https://www.branah.com/unicode-converter
          }
        }
        return formatted_value;
      },

      //PROJECT SPECIFIC CODE BELOW: //TODO: Split this to a separate file
      convertPropertiesToBTableFields(properties, prefix = '') {
        properties = properties.filter(property => {
          if (_.get(property, 'type') == 'point') {
            return false;
          }

          if (_.get(property, 'type') == 'polygon') {
            return false;
          }

          if (_.get(property, 'hidden_in_index') == true) {
            return false;
          }

          return true;
        });

        var fields = _.map(properties, property => {
          //FORMATTER
          let property_type = property['type'];
          let cache_by_property = {};
          let formatter = (value, key, item) => {
            // console.log('formatter for', property['property_key']);
            let formatted_value = null;
            let deep_value = this.getDeepProperty(item, property, cache_by_property); //fix to allow getting deep item
            if (deep_value === undefined) {
              // return '{{not_loaded}}';
              return undefined;
            }
            if (property_type === 'array') {
              formatted_value = JSON.stringify(deep_value);
            } else if (property_type === 'array_property') {
              let subproperty_path = property['array_property']['property_key'];
              // var subproperty_type = property['array_property']['type'];
              // console.log('deep_value', deep_value, 'key', key)
              // return deep_value ? JSON.stringify(deep_value.map(value => _.get(value, subproperty_key))) : null;
              if (deep_value) {
                formatted_value = deep_value
                  .map(value => {
                    value = _.get(value, subproperty_path);
                    // return value;

                    //presenter
                    let formatted_value = this.propertyPresenter(property, value);

                    return formatted_value;
                  })
                  .join(', ');
              }
            } else {
              if (Array.isArray(deep_value)) {
                //this could be json cast array from API
                let values = deep_value;
                if (values) {
                  formatted_value = values
                    .map(value => {
                      // return value;

                      //presenter
                      let formatted_value = this.propertyPresenter(property, value);

                      return formatted_value;
                    })
                    .join(' ; \n'); //TODO: configure/specify separator
                } else {
                  formatted_value = null;
                }
              } else {
                formatted_value = deep_value;
              }
            }

            // return formatted_value;

            //presenter
            formatted_value = this.propertyPresenter(property, formatted_value);

            return formatted_value;
          };

          //SORTABILITY
          let sortable = true;
          if (property['virtual'] == true) {
            sortable = false;
          }
          if (property_type == 'base64') {
            sortable = false;
          }

          let field_key = `${prefix}${property['property_key']}`;

          //TODO: this is for vue bootstrap's table
          return {
            key: field_key,
            label: property['property_name'],
            property: property,
            formatter: formatter,
            sortable: sortable,
            cellVariantFunc: params => {
              // let value = params[property['property_key']];
              let deep_value = this.getDeepProperty(params, property);
              // console.log('cellVariantFunc!!!', property['property_key'], deep_value);
              if (deep_value === undefined) {
                return 'light ';
              }
              // return 'info';
              return null;
            },
          };
        });

        return fields;
      },
      getRecursiveRelationshipBTableFields(relationship) {
        // console.log("relationship_name", relationship_name, relationship);
        var ori_properties = relationship['properties'];
        var index_properties = _.get(relationship, ['to', 'frontend', 'index_properties']);

        //merge array of objects
        var properties = _.map(index_properties, index_property => {
          let ori_property = _.find(ori_properties, { property_key: index_property['property_key'] });
          return _.merge(ori_property, index_property);
        });

        if (!_.isEmpty(properties)) {
          return this.convertPropertiesToBTableFields(properties);
        } else {
          return this.getClassBTableFields(relationship['to']['class_name']);
        }
        // console.log("properties", properties);
      },
      getRelationshipBTableFields(relationship) {
        // console.log("relationship_name", relationship_name, relationship);
        var properties = relationship['properties'];
        // console.log("properties", properties);
        return this.convertPropertiesToBTableFields(properties, this.getRelationAlias(relationship) + '_');
      },
      getClassBTableFields(class_name) {
        var class_ = this.getClass(class_name);
        // console.log("class_", class_name, class_);
        var properties = class_['properties'];

        let index_property_keys = class_['index_property_keys'];

        let reordered_properties = [];

        if (index_property_keys) {
          // console.log(index_property_keys)
          index_property_keys.forEach(property_key => {
            let property = _.find(properties, { property_key: property_key });
            reordered_properties.push(property);
          });
        } else {
          reordered_properties = properties;
        }

        // console.log("properties", properties);
        return this.convertPropertiesToBTableFields(reordered_properties, class_name + '_');
      },

      randStr(length = 7) {
        //ref: https://stackoverflow.com/a/1349426/3553367
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },

      randInt(min, max) {
        //ref: https://stackoverflow.com/a/1527820/3553367
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },

      initializeParamRelations(params, target_relationships) {
        // console.log('initializeParamRelations - start', params, target_relationships);
        //initialise relationship params

        target_relationships.forEach(relationship => {
          let relation_alias = this.$d.getRelationAlias(relationship);
          // console.log('initializeParamRelations - for ', relationship['from']['class_name'], relationship['relationship_name']);

          if (!params[relation_alias] || !Array.isArray(params[relation_alias])) {
            // console.log('initializeParamRelations - initialize empty relationship', relation_alias);
            let init_relations = [];
            if (params['id']) {
              init_relations = null; //so that it can be identified as not loaded
            }
            if (this.$d.relIsViewOnly(relationship)) {
              init_relations = null; //so that it can be identified as not loaded
            }

            Vue.set(params, relation_alias, init_relations); //workaround to fix reactivity
          } else {
            // console.log('initializeParamRelations - initialize existing relationship', relation_alias);
            //if already defined
            Vue.set(params, relation_alias, _.cloneDeep(params[relation_alias])); //workaround to fix reactivity
            params[relation_alias].forEach((relation, relation_index) => {
              let relation_properties = params[relation_alias][relation_index][relationship['relationship_key']];
              if (_.isNil(relation_properties)) {
                Vue.set(params[relation_alias][relation_index], relationship['relationship_key'], {}); //for the relationship's properties
              } else {
                Vue.set(params[relation_alias][relation_index], relationship['relationship_key'], _.cloneDeep(relation_properties)); //for the relationship's properties
              }

              //set default values for the relation
              //TODO: same code as in SelectingSearch - addItem
              relationship['properties'].forEach(property => {
                let relation_property_value = params[relation_alias][relation_index][relationship['relationship_key']][property['property_key']];
                if (_.isNil(relation_property_value)) {
                  let default_value = null;
                  if (property['default_using']) {
                    default_value = relation[property['default_using']];
                  }
                  Vue.set(params[relation_alias][relation_index][relationship['relationship_key']], property['property_key'], default_value);
                } else {
                  Vue.set(
                    params[relation_alias][relation_index][relationship['relationship_key']],
                    property['property_key'],
                    _.cloneDeep(relation_property_value)
                  );
                }
              });
            });
          }
        });
      },

      updateVirtualRelationships(params, target_relationships) {
        //update relationship params

        const day_of_week_table = [
          //TODO: might need to preload this from cache
          { id: 1, name: '"Sunday"', number: 1 },
          { id: 2, name: '"Monday"', number: 2 },
          { id: 3, name: '"Tuesday"', number: 3 },
          { id: 4, name: '"Wednesday"', number: 4 },
          { id: 5, name: '"Thursday"', number: 5 },
          { id: 6, name: '"Friday"', number: 6 },
          { id: 7, name: '"Saturday"', number: 7 },
        ];
        target_relationships.forEach(relationship => {
          let target_relation_alias = this.getRelationAlias(relationship);

          if (this.relationshipIsVirtual(relationship)) {
            console.log('relationship is virtual, try to restore', target_relation_alias);
            if (this.relationshipIsSubquery(relationship)) {
              let subquery_name = _.get(relationship, ['subquery_type', 'subquery_name']);
              if (subquery_name == 'through') {
                let relationships_path = _.get(relationship, ['subquery_type', 'subquery_params', 'relationships_path']);
                // console.log(params, relationships_path);
                let deep_value = this.getDeep(params, relationships_path);
                if (!_.isNil(deep_value)) {
                  console.log('restored', target_relation_alias, deep_value);
                  params[target_relation_alias] = deep_value;
                }
                // console.log(deep_value);
              } else if (subquery_name == 'on_day_of_week') {
                let date_property_key = _.get(relationship, ['subquery_type', 'subquery_params', 'date_property_key']);
                let date = params[date_property_key];
                if (!_.isNil(date)) {
                  let day_of_week_number = moment(date).day() + 1; //offset sunday as first day of week
                  console.log('day of week', date, day_of_week_number);
                  let day_of_week = _.find(day_of_week_table, { number: day_of_week_number });
                  if (!_.isNil(day_of_week)) {
                    params[target_relation_alias] = [day_of_week];
                  }
                }
              } else {
                //virtual relationship is custom, require server to process
              }
            } else if (this.relationshipIsMysqlView(relationship)) {
              let mysql_view_name = _.get(relationship, ['mysql_view_type', 'mysql_view_name']);
              if (mysql_view_name == 'through') {
                let relationships_path = _.get(relationship, ['mysql_view_type', 'mysql_view_params', 'relationships_path']);
                // console.log(params, relationships_path);
                let deep_value = this.getDeep(params, relationships_path);
                if (!_.isNil(deep_value)) {
                  console.log('restored', target_relation_alias, deep_value);
                  params[target_relation_alias] = deep_value;
                }
                // console.log(deep_value);
              } else if (mysql_view_name == 'on_day_of_week') {
                let date_property_key = _.get(relationship, ['mysql_view_type', 'mysql_view_params', 'date_property_key']);
                let date = params[date_property_key];
                if (!_.isNil(date)) {
                  let day_of_week_number = moment(date).day() + 1; //offset sunday as first day of week
                  console.log('day of week', date, day_of_week_number);
                  let day_of_week = _.find(day_of_week_table, { number: day_of_week_number });
                  if (!_.isNil(day_of_week)) {
                    params[target_relation_alias] = [day_of_week];
                  }
                }
              } else {
                //virtual relationship is custom, require server to process
              }
            }
          } else {
          }
        });
      },

      withVirtualPropertiesByRelationship(params, relationship) {
        let with_virtual_properties = [];

        let requested_with_virtual_properties = _.get(relationship, ['to', 'with_virtual_properties']);

        if (requested_with_virtual_properties) {
          // console.log('requested_with_virtual_properties', requested_with_virtual_properties);

          let virtual_property_parameter_paths = _.get(relationship, ['to', 'virtual_property_parameter_paths']);
          // console.log('virtual_property_parameter_paths', virtual_property_parameter_paths);
          requested_with_virtual_properties.forEach(virtual_property_key => {
            let property = this.$d.getProperty(this.$d.getClass(relationship['to']['class_name'])['properties'], virtual_property_key);

            // console.log('virtual_property', virtual_property_key, property);

            let virtual_property_parameters = property['virtual_property_parameters'];

            if (virtual_property_parameters) {
              //requested virtual_property has parameters
              let parameters = null;
              virtual_property_parameters.forEach(virtual_property_parameter => {
                if (virtual_property_parameter_paths) {
                  let virtual_property_parameter_path = virtual_property_parameter_paths[virtual_property_parameter];

                  if (virtual_property_parameter_path) {
                    /* console.log(
                  'virtual_property_parameter_path',
                  virtual_property_parameter,
                  virtual_property_parameter_path,
                  virtual_property_parameter_path['class_name']
                ); */

                    let initial_class_name = virtual_property_parameter_path['class_name'];
                    let property_path = virtual_property_parameter_path['property_path'];

                    let initial_direction = this.$d.relationshipDirectionTowardsClass(relationship, this.$d.getClass(initial_class_name));

                    // console.log('initial_direction', initial_direction);

                    if (initial_direction == 'from') {
                      let deep_value = this.$d.getDeep(params, property_path);

                      // console.log('deep_value', virtual_property_parameter, params, property_path, deep_value);

                      if (!_.isNil(deep_value)) {
                        if (!parameters) {
                          parameters = {};
                        }
                        parameters[virtual_property_parameter] = deep_value;
                      } else {
                        //no value found
                      }
                    } else {
                      throw 'have not implemented the to direction';
                    }
                  }
                }
              });

              if (parameters) {
                with_virtual_properties.push({
                  property_key: virtual_property_key,
                  parameters: parameters,
                });
              } else {
                console.warn(`Requested virtual property ${virtual_property_key} missing parameter, did not include`);
              }
            } else {
              //requested virtual_property doesn't require  any parameters
              with_virtual_properties.push(virtual_property_key);
            }
          });
        }

        return with_virtual_properties;
      },

      isWithRelationshipsSatisfied(params, with_relationships) {
        // console.log('isWithRelationshipsSatisfied', _params, with_relationships);

        for (let i = 0; i < with_relationships.length; i++) {
          let with_relationship = with_relationships[i];

          let relation_alias = this.$d.getRelationAliasByNames(with_relationship['from_class_name'], with_relationship['relationship_name']);
          let relations = params[relation_alias];

          console.log('check', relation_alias, relations);
          if (relations && Array.isArray(relations)) {
            //TODO: should also check properties too, virtual or not
            if (with_relationship['with_relationships']) {
              if (relations.length >= 1) {
                console.log('is satisfied so far');
                console.log('check deeper', with_relationship['with_relationships']);
                //NOTE: does not guarantee satisfaction if with_relationships has sorting/filtering. due to limitation that the relation alias remains the same even if filtered
                if (!this.$d.isWithRelationshipsSatisfied(relations[0], with_relationship['with_relationships'])) {
                  //checks only 1, might need to loop all?
                  return false;
                }
              }
            } else {
              console.log('is satisfied at this end');
            }
          } else {
            console.log('is not satisfied');
            return false;
          }
          /* relations.forEach(relation => {

        }); */
        }

        return true;
      },

      hasRole(config = {}) {
        let user = config['user'] || this.$auth.user;
        let from_class_name = config['from_class_name'];
        let relationship_name = config['relationship_name'] || 'WithUser';

        let user_role = _.get(user, [this.$d.getRelationAliasByNames(from_class_name, relationship_name)]);
        return user_role && user_role.length >= 1;
      },

      isBase64Image(base64_str) {
        if (_.isNil(base64_str)) {
          return false;
        }
        if (typeof base64_str != 'string') {
          return false;
        }

        return base64_str.substring(0, 11) == 'data:image/';
      },

      isImageUrl(url) {
        //ref: https://thewebdev.info/2021/08/15/how-to-verify-that-an-url-is-an-image-url-with-javascript/
        if (typeof url !== 'string') {
          return false;
        }
        return url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gim) !== null;
      },

      isBase64Pdf(base64_str) {
        if (_.isNil(base64_str)) {
          return false;
        }
        if (typeof base64_str != 'string') {
          return false;
        }

        return base64_str.substring(0, 21) == 'data:application/pdf;';
      },

      isPdfUrl(url) {
        //ref: https://thewebdev.info/2021/08/15/how-to-verify-that-an-url-is-an-image-url-with-javascript/
        if (typeof url !== 'string') {
          return false;
        }
        let result = url.match(/^http[^\?]*.(pdf)(\?(.*))?$/gim) !== null;
        console.log('isPdfUrl', result);
        return result;
      },

      isBase64Video(base64_str) {
        if (_.isNil(base64_str)) {
          return false;
        }
        if (typeof base64_str != 'string') {
          return false;
        }

        return base64_str.substring(0, 11) == 'data:video/';
      },

      isVideoUrl(url) {
        //ref: https://thewebdev.info/2021/08/15/how-to-verify-that-an-url-is-an-image-url-with-javascript/
        if (typeof url !== 'string') {
          return false;
        }
        let result = url.match(/^http[^\?]*.(mp4|mkv|avi)(\?(.*))?$/gim) !== null;
        console.log('isVideoUrl', result);
        return result;
      },

      getFileTempurlParam(property, params) {
        let file_tempurl_param = null;
        let tempurl_property_key = _.get(property, ['config', 'file', 'tempurl_property_key']);
        // console.log('tempurl_property_key', tempurl_property_key)
        if (tempurl_property_key) {
          // if (this.propertyType == 'file') {
          if (!_.isNil(params, tempurl_property_key)) {
            file_tempurl_param = params[tempurl_property_key];
          }
          // }
        }
        return file_tempurl_param;
      },

      getTitlePropertyKeys(class_){
        let title_property_key = _.get(class_, ['frontend', 'title_property_key']);
        return _.get(class_, ['frontend', 'title_property_keys']) || (title_property_key ? [title_property_key] : []);
      },

      generateTitleFromTitlePropertyKeys(params, title_property_keys){
  
        let titles = [];
  
        title_property_keys.forEach((title_property_key)=>{
          titles.push(_.get(params, [title_property_key], "null"));
        })
  
        return titles.join(" - ")
      },

      convertToDropdownOption(class_, item){
        /* let text = item['name']
          ? item['name']
          : _.get(class_, ['frontend', 'title_property_key'])
          ? item[class_['frontend']['title_property_key']]
          : 'id: ' + item['id'];
        */
  
        let title_property_keys = this.getTitlePropertyKeys(class_)
  
        let text = 'id: ' + item['id']; //default dropdown text
        if (title_property_keys) {
          text = this.generateTitleFromTitlePropertyKeys(item, title_property_keys);
        } else if (item['name']) {
          text = item['name']; //use name as default if available
        }
  
        if (!text) {
          console.error('no dropdown text???!!?', item, _.get(class_, ['frontend', 'title_property_key']), class_);
        }
  
        return {
          value: item['id'],
          text: text,
          real_data: item,
        }
      },

    },
  });

  if (!Vue.prototype.$d) {
    Object.defineProperties(Vue.prototype, {
      $d: {
        get() {
          return descriptorVue;
        },
      },
    });
  } else {
    console.error('Another $d has been defined, will skip this one');
  }
}
