// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import router from './router'
import store from './store/store'

import './registerServiceWorker'

////// CoreUI

import '@coreui/icons/css/coreui-icons.min.css';
/* Import Simple Line Icons Set */
import 'simple-line-icons/css/simple-line-icons.css';
/* Import Flag Icons Set */
import 'flag-icon-css/css/flag-icon.min.css';
/* Import Bootstrap Vue Styles */
import 'bootstrap-vue/dist/bootstrap-vue.css';
// Import Main styles for this application
import './assets/scss/style.scss';

import 'vue-select/dist/vue-select.css';

////// Quasar

import './styles/quasar.styl'
import 'quasar/dist/quasar.ie.polyfills'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css' //conflicts with CoreUI's fontawersome
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import '@quasar/extras/mdi-v3/mdi-v3.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import {
  Quasar,
  // QLayout,
  // QHeader,
  // QDrawer,
  // QPageContainer,
  // QPage,
  // QToolbar,
  // QToolbarTitle,
  // QBtn,
  // QIcon,
  // QList,
  // QItem,
  // QItemSection,
  // QItemLabel,
} from 'quasar'

Vue.use(Quasar, {
  config: {},
  components: {
    // QLayout,
    // QHeader,
    // QDrawer,
    // QPageContainer,
    // QPage,
    // QToolbar,
    // QToolbarTitle,
    // QBtn,
    // QIcon,
    // QList,
    // QItem,
    // QItemSection,
    // QItemLabel,
  },
  directives: {
  },
  plugins: {
  }
})


////// BootstrapVue

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);


////// NProgress

//ref: https://github.com/vue-bulma/nprogress/issues/18#issuecomment-408246877
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
  easing: 'ease',
  speed: 500
});

Vue.prototype.$NProgress = NProgress;

////// Vue MQ (MediaQuery)

import VueMq from 'vue-mq';
Vue.use(VueMq, {
  breakpoints: {
    // https://bootstrap-vue.js.org/docs/components/layout
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity
  },
  defaultBreakpoint: 'xs' // customize this for SSR
});

////// moment

import moment from 'moment';
Vue.prototype.$moment = moment;


////// VueMoment

import * as VueMoment from 'vue-moment'
Vue.use(VueMoment);


////// VueGoogleMaps

//ref: https://github.com/xkjyeah/vue-google-maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

////// DESCRIPTOR

import DescriptorPlugins from './descriptor/plugins';
let login_path = '/pages/login'
let api_root = process.env.VUE_APP_API_ROOT
let api_timeout = process.env.VUE_APP_API_TIMEOUT
Vue.use(DescriptorPlugins, { router, store, login_path, api_root, api_timeout});


router.beforeEach((to, from, next) => {
  //reset to first tab everytime route changes (workaround for Account tabs)
  console.log(store.state.tabIndex)
  store.state.tabIndex = 0
  next() // make sure to always call next()!
})

//Global helper functions
Vue.mixin({
  methods: {
    hello() {
      console.log('hello')
    }
  }
})

import App from './App'
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
